import CustomField from './CustomField';
import {Fields, Model} from '@vuex-orm/core';

export default class OrderField extends Model {
    public static modelName = 'OrderField';
    public static entity = 'order-fields';

    public id!: number;
    public orderId!: number;
    public groupName!: string;
    public name!: string;
    public displayName!: string;
    public value!: string;
    //temp
    public customField?: CustomField;

    public static fields(): Fields {
        return {
             id: this.number(0),
             orderId: this.number(0),
             groupName: this.string(''),
             name: this.string(''),
             displayName: this.string(''),
             value: this.string(''),
             customField: this.attr({}),
        };
    }
}
