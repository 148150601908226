import {Fields, Model} from '@vuex-orm/core';

export default class PaymentPlanSettings extends Model {
    public static modelName = 'PaymentPlanSettings';
    public static entity = 'payment-plan-settings';

    public customerCanSubscribe!: boolean;
    public customerCanCancel!: boolean;
    public customerCanModifyAddOns!: boolean;

    public static fields(): Fields {
        return {
            customerCanSubscribe: this.boolean(false),
            customerCanCancel: this.boolean(false),
            customerCanModifyAddOns: this.boolean(false),
        };
    }
}