import {useAxios} from './useAxios';
import {AxiosResponse} from 'axios';
import {Model} from '@vuex-orm/core';
import {useNotificationToast} from './useNotificationToast';
import {RequestMethods} from './useRawAxios';

export function useOrm<T extends typeof Model>(model: T, route='') {

    const {
        axiosSend,
        axiosGet,
        axiosDelete,
    } = useAxios();

    const {
        emitNotice,
    } = useNotificationToast();

    const isPayload = (response: Record<string, unknown>): boolean => {
        return !isNaN(+Object.keys(response)[0]);
    };

    const insertOrUpdateModel = (response: Record<string, unknown>) => {
        if(!response) { return; }
        if(!isPayload(response)) {
            model.insertOrUpdate({data: response}).then();
            return;
        }
        for(const key in response) {
            const object = response[key];
            model.insertOrUpdate({data: object}).then();
        }
    };

    const syncModels = (response: Record<string, unknown>) => {
        model.create({ data: response }).then();
    };

    const ormAxios = (method: RequestMethods) => {
        return function (action: string, params: Record<string, unknown> = {}) {
            return axiosSend(method)<AxiosResponse>(`${route}/${model.entity}${action}`, params, true)
                .then(response => {
                    if(Object.keys(response.data).includes('id') && response.data.id) {
                        insertOrUpdateModel(response.data);
                    }
                    return Promise.resolve(response.data);
                }).catch(exception => {
                    const errorMessage = exception.data.error ? exception.data.error : exception.data[0];
                    emitNotice(0, errorMessage, []);
                    return Promise.reject(exception);
                });
        };
    };

    const post = ormAxios('POST');
    const put = ormAxios('PUT');
    const patch = ormAxios('PATCH');
    const remove = ormAxios('DELETE');

    const buildQueryParams = (params: Record<string, unknown> = {}): string => {
        let queryParams = '';
        for (const key in params) {
            const value = params[key];
            if(!value) { continue; }

            if (queryParams === '') {
                queryParams += `${key}=${value}`;
            } else {
                queryParams += `&${key}=${value}`;
            }
        }
        return queryParams;
    }

    const get = (url: string, params: Record<string, unknown> = {}) => {
        const queryParams = buildQueryParams(params);
        return axiosGet<AxiosResponse>(`${url}?${queryParams}`, {}, true)
            .then(response => {
                if(Object.keys(response.data).includes('id') && response.data.id) {
                    insertOrUpdateModel(response.data);
                }
                return Promise.resolve(response.data);
            }).catch(exception => {
                const errorMessage = exception.data.error ? exception.data.error : exception.data[0];
                emitNotice(0, errorMessage, []);
                return Promise.reject(errorMessage);
            });
    };

    const getAll = (params: Record<string, unknown> = {}, url: string = '') => {
        const queryParams = buildQueryParams(params);
        if(url !== '') {
            return axiosGet<AxiosResponse<{
                items: Record<string, unknown>
            }>>(`${url}?${queryParams}`, {}, true)
                .then(rawResponse => {
                    insertOrUpdateModel(rawResponse.data.items);
                    return Promise.resolve(rawResponse.data);
                }).catch(exception => {
                    return Promise.reject(exception);
                });
        }
        return axiosGet<AxiosResponse<{
            items: Record<string, unknown>
        }>>(`${route}/${model.entity}?${queryParams}`, {}, true)
        .then(rawResponse => {
            insertOrUpdateModel(rawResponse.data.items);
            return Promise.resolve(rawResponse.data);
        }).catch(exception => {
            return Promise.reject(exception);
        });
    };

    const getById = (id: number) => {
        return axiosGet<AxiosResponse>(`${route}/${model.entity}/${id}`, {}, true)
        .then(response => {
            insertOrUpdateModel(response.data);
            return Promise.resolve(response.data);
        }).catch(exception => {
            return Promise.reject(exception);
        });
    };

    const deleteById = (id: number, params = {}) => {
        return axiosDelete<AxiosResponse>(`${route}/${model.entity}/${id}`, params, true)
        .then(response => {
            model.delete(id).then();
            return Promise.resolve(response.data);
        }).catch(exception => {
            emitNotice(0, exception.data.error, []);
            return Promise.reject(exception);
        });
    };

    return {
        get,
        getAll,
        getById,
        deleteById,
        insertOrUpdateModel,
        create: post,
        updatePut: put,
        updatePatch: patch,
        remove,
        syncModels,
    };
}
