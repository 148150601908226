import {ICartItem} from '../../types';

const isCartItem = (payload: unknown): payload is ICartItem => {
    return payload !== undefined;
};

export default class CartItem implements ICartItem {
    public id: number;
    public cartId: number;
    public childrenCartItems?: CartItem[];
    public dateCreated: string;
    public dateUpdated: string;
    public netPrice: number;
    public notes: string;
    public offeringId: number;
    public offeringType: number;
    public parentId: number;
    public price: number;
    public quantity: number;
    public totalPrice: number;
    public itemGroupId: number;
    public offeringName: string;
    public offeringImageUrl?: string;
    public isClaimedItem: boolean;
    public selectedOptions?: Record<string, string>;

    public constructor(cartItem?: unknown) {
        if(!isCartItem(cartItem)) {
            return;
        }

        this.id = cartItem.id;
        this.cartId = cartItem.cartId;
        this.childrenCartItems = cartItem.childrenCartItems;
        this.dateCreated = cartItem.dateCreated;
        this.dateUpdated = cartItem.dateUpdated;
        this.netPrice = cartItem.netPrice;
        this.notes = cartItem.notes;
        this.offeringId = cartItem.offeringId;
        this.offeringType = cartItem.offeringType;
        this.parentId = cartItem.parentId;
        this.price = cartItem.price;
        this.quantity = cartItem.quantity;
        this.totalPrice = cartItem.totalPrice;
        this.itemGroupId = cartItem.itemGroupId;
        this.offeringName = cartItem.offeringName;
        this.offeringImageUrl = cartItem.offeringImageUrl;
        this.isClaimedItem = cartItem.isClaimedItem;
        this.selectedOptions = cartItem.selectedOptions;
    }
}