import {Fields, Model} from '@vuex-orm/core';

export default class Subdomain extends Model {

    public static modelName = 'Subdomain';
    public static entity = 'subdomain';

    public id: number;
    public merchantId: number;
    public businessId: number;
    public dateCreated: string;
    public dateUpdated: string;
    public subdomain: string;
    public active: boolean;

    public static fields(): Fields {
        return {

            id: this.number(0),
            merchantId: this.number(0),
            businessId: this.number(0),
            dateCreated: this.string('0000-00-00 00:00:00'),
            dateUpdated: this.string('0000-00-00 00:00:00'),
            subdomain: this.string(''),
            active: this.boolean(false),
        };
    }

}