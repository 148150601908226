export const SHIPPING_TYPES = {
    'BY_WEIGHT': 1,
    'BY_AMOUNT': 2,
};

export const SHIPPING_TYPES_NAMES = {
    [SHIPPING_TYPES.BY_WEIGHT]: 'Based on order weight',
    [SHIPPING_TYPES.BY_AMOUNT]: 'Based on order price',
};

export const SHIPPING_TYPE_API_CODES = {
    [SHIPPING_TYPES.BY_WEIGHT]: 'byWeight',
    [SHIPPING_TYPES.BY_AMOUNT]: 'byAmount',
};

export const SHIPPING_TYPE_UNIT = {
    [SHIPPING_TYPES.BY_WEIGHT]: 'lbs',
    [SHIPPING_TYPES.BY_AMOUNT]: '$',
};