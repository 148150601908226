import {Fields, Model} from '@vuex-orm/core';

export default class ItemLevelDiscount extends Model {

    public static modelName = 'ItemLevelDiscount';
    public static entity = 'item-level-discounts';

    public orderItemId: number;
    public discountDetails: string;
    public amount: number;
    public quantityRefunded: number;
    public quantity: number;

    public static fields(): Fields {
        return {
            orderItemId: this.number(0),
            discountDetails: this.string(''),
            amount: this.number(0.00),
            quantityRefunded: this.number(0.00),
            quantity: this.number(0.00),
        };
    }
}
