import {Fields, Model} from '@vuex-orm/core';

export default class StoreSettings extends Model {

    public static modelName = 'StoreSettings';
    public static entity = 'store-settings';

    public id: number;
    public merchantId: number;
    public enabled: number;
    public showProductReview: number;
    public showFeedback: number;
    public storeName: string;
    public customerLoginView: number;
    public customerLoginCheckout: number;
    public calculateShipping: number;
    public taxLocation: number;
    public locationCountry: string;
    public locationProvince: string;
    public allowDifferentShipping: number;
    public pickupOnly: number;
    public pickupInterval: number;
    public pickupBufferTime: number;
    public paymentTermsAgree: number;
    public preauthOnly: number;
    public allowDiscounts: number;
    public homePageEnabled: number;
    public cartEnabled: number;
    public socialMediaEnabled: number;
    public analyticsEnabled: number;
    public blogEnabled: number;
    public storeDetailsEnabled: number;
    public lastUpdated: string;
    public customerSelfRegister: number;
    public showSku: number;
    public showStockStatus: number;
    public showMerchantPolicy: number;
    public noPaymentInvoicing: number;
    public joinMarketing: number;
    public disableSeo: number;
    public quickOrderMode: number;
    public showInventoryCount: number;
    public customerLoginViewStore: number;
    public limitCheckoutToStoreHours: number;
    public allowStoreEmbed: number;
    public demoMode: number;
    public orderMax: number;
    public orderProductsBy: number;
    public preOrderDays: number;
    public allowDifferentPickupInfo: number;

    public static fields(): Fields {
        return {

            id: this.number(0),
            merchantId: this.number(0),
            enabled: this.number(0),
            showProductReview: this.number(0),
            showFeedback: this.number(0),
            storeName: this.string(''),
            customerLoginView: this.number(0),
            customerLoginCheckout: this.number(0),
            calculateShipping: this.number(0),
            taxLocation: this.number(0),
            locationCountry: this.string(''),
            locationProvince: this.string(''),
            allowDifferentShipping: this.number(0),
            pickupOnly: this.number(0),
            pickupInterval: this.number(0),
            pickupBufferTime: this.number(0),
            paymentTermsAgree: this.number(0),
            preauthOnly: this.number(0),
            allowDiscounts: this.number(0),
            homePageEnabled: this.number(0),
            cartEnabled: this.number(0),
            socialMediaEnabled: this.number(0),
            analyticsEnabled: this.number(0),
            blogEnabled: this.number(0),
            storeDetailsEnabled: this.number(0),
            lastUpdated: this.string('0000-00-00 00:00:00'),
            customerSelfRegister: this.number(0),
            showSku: this.number(0),
            showStockStatus: this.number(0),
            showMerchantPolicy: this.number(0),
            noPaymentInvoicing: this.number(0),
            joinMarketing: this.number(0),
            disableSeo: this.number(0),
            quickOrderMode: this.number(0),
            showInventoryCount: this.number(0),
            customerLoginViewStore: this.number(0),
            limitCheckoutToStoreHours: this.number(0),
            allowStoreEmbed: this.number(0),
            demoMode: this.number(0),
            orderMax: this.number(0),
            orderProductsBy: this.number(0),
            preOrderDays: this.number(0),
            allowDifferentPickupInfo: this.number(0),
        };
    }

}


