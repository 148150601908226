import {Fields, Model} from '@vuex-orm/core';

export default class Service extends Model {
    public static modelName = 'Service';
    public static entity = 'services';

    public id!: number;
    public dateCreated!: string;
    public dateUpdated!: string;
    public merchantId!: number;
    public name!: string;
    public sku!: string;
    public categoryId!: number;
    public price!: number;
    public salePrice!: number;
    public availability!: number;
    public availabilityRetail!: number;
    public availabilityAppointment!: number;
    public description!: string;
    public backgroundColor?: string;
    public taxExempt!: number;
    public commodityCode?: string;
    public unitOfMeasure?: string;
    public seoUrl?: string;
    public seoDescription?: string;
    public seoTags?: string;

    public static fields(): Fields {
        return {
            id: this.number(0),
            dateCreated: this.string('0000-00-00 00:00:00'),
            dateUpdated: this.string('0000-00-00 00:00:00'),
            merchantId: this.number(0),
            name: this.string(''),
            sku: this.string(''),
            categoryId: this.number(0),
            price: this.number(0),
            salePrice: this.number(0),
            availability: this.number(0),
            availabilityRetail: this.number(0),
            availabilityAppointment: this.number(0),
            description: this.string(''),
            backgroundColor: this.string(''),
            taxExempt: this.number(0),
            commodityCode: this.string(''),
            unitOfMeasure: this.string(''),
            seoUrl: this.string(''),
            seoDescription: this.string(''),
            seoTags: this.string(''),
        };
    }
}
