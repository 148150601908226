import {Fields, Model} from '@vuex-orm/core';
import ItemGroup from './ItemGroup';
import CartItem from './CartItem';

export default class ClaimedItem extends Model {

    public static modelName = 'ClaimedItem';
    public static entity = 'claimed-items';

    public id: number;
    public dateCreated: string;
    public dateUpdated: string;
    public merchantId: number;
    public cartId: number;
    public itemId: number;
    public itemGroupId: number;
    public itemGroup: ItemGroup;
    public item: CartItem;

    public static fields(): Fields {
        return {
            id: this.number(0),
            dateCreated: this.string('0000-00-00 00:00:00'),
            dateUpdated: this.string('0000-00-00 00:00:00'),
            merchantId: this.number(0),
            cartId: this.number(0),
            itemId: this.number(0),
            itemGroupId: this.number(0),
            itemGroup: this.hasOne(ItemGroup, 'id', 'itemGroupId'),
            item: this.hasOne(CartItem, 'id', 'itemId'),
        };
    }
}
