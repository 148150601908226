import {Fields, Model} from '@vuex-orm/core';

export default class Link extends Model {

    public static modelName = 'Link';
    public static entity = 'link';

    public title: string;
    public description: string;
    public url: string;

    public static fields(): Fields {
        return {
            title: this.string(''),
            description: this.string(''),
            url: this.string(''),
        };
    }

}