import {useActors} from './useActors';
import {useSettings} from './useSettings';

export function useDocumentStyle() {

    const { merchant } = useActors();
    const { brandColorTheme } = useSettings();

    function setDocumentStyle (resetBody=false) {
        if(resetBody) {
            document.body.className = '';
            document.body.removeAttribute('style');
        }
        const title = merchant.value.businessName;
        document.title = title;
        if(brandColorTheme.value.length) {
            document.body.classList.add(brandColorTheme.value);
        }
        const favicon = document.getElementById('favicon') as HTMLLinkElement;
        const matchLetterOnly = title.match(/[a-zA-Z]/);
        const firstLetterChar = matchLetterOnly ? matchLetterOnly[0] : title.charAt(0);
        favicon.href = (new URL('/app/favicons/favicon-' + firstLetterChar.toLowerCase() + '.ico', import.meta.url)).href;
    }

    return { setDocumentStyle };
}
