import {Ref, ref} from 'vue';

export function useAsyncCall<T>(promise: Promise<T>) {

    const state = ref<T>(null) as Ref<T>;
    const success = ref(false);
    const error = ref(null);
    const complete = ref(false);

    promise.then((result: T) => {
            state.value = result;
            success.value = true;
        })
        .catch(e => error.value = e)
        .finally(() => complete.value = true);

    return {
        state,
        success,
        error,
        complete,
    };
}
