import { datadogRum } from '@datadog/browser-rum';
import mitt from 'mitt';
import { createPinia } from 'pinia';
import { createApp } from 'vue';
import VueClickAway from 'vue3-click-away';
import App from './App.vue';
import IntersectDirective from './directives/intersect';
import ResizeDirective from './directives/resize';
import './main.scss';
import router from './router';
import './scss/side-cart-scss/main.scss';
import { store } from './store/store';
import { logger } from './store/utilities';

if (!/\.test$/.test(window.location.hostname)) {
    datadogRum.init({
        applicationId: '2d47bb9b-9d59-432c-b817-18e1ad8dd242',
        clientToken: 'pubfac58a5e2bd099debb583a85ea41bd30',
        site: 'us5.datadoghq.com',
        proxy: 'https://apm-proxy.helcim.com/apm/in',
        service: 'ecommerce',
        env: 'production',
        sessionSampleRate: 100,
        sessionReplaySampleRate: 20,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input',
        allowedTracingUrls: [/https:\/\/.*\.helcim\.com/, /https:\/\/.*\.myhelcim\.com/, /https:\/\/.*\.helcim\.app/],
    });
    const username = sessionStorage.getItem('username');
    if (username) {
        datadogRum.setUser({
            name: username,
        });
    }
}

const emitter = mitt();

const pinia = createPinia()
    .use(({ store }) => {
        store.$onAction(({ store, name, args, onError, after }) => {
            if(args.length > 0) {
                logger.info(`[${store.$id}]: action ${name} was invoked`, { args: { ...args } });
            } else {
                logger.info(`[${store.$id}]: action ${name} was invoked`);
            }
            after(result => {
                if(!result) {
                    logger.debug(`[${store.$id}]: action ${name} returned`);
                    return;
                }
                logger.debug(`[${store.$id}]: action ${name} returned`, { result: result });
            });
            onError(error => {
                logger.error(`[${store.$id}]: action ${name} has thrown`, error);
            });
        });
    });

const app = createApp(App)
    .provide('emitter', emitter)
    .use(pinia)
    .use(store)
    .use(router)
    .use(VueClickAway)
    .directive('intersect', IntersectDirective)
    .directive('resize', ResizeDirective);

app.mount('#app');
