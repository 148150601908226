import {Fields, Model} from '@vuex-orm/core';

export default class Deposit extends Model {
    public static modelName = 'Deposit';
    public static entity = 'deposits';

    public id!: number;
    public dateCreated!: string;
    public merchantId!: number;
    public invoiceId!: number;
    public amount!: number;
    public amountPaid!: number;
    public dateDue!: string;

    public static fields(): Fields {
        return {
            id: this.number(0),
            dateCreated: this.string('0000-00-00 00:00:00'),
            merchantId: this.number(0),
            invoiceId: this.number(0),
            amount: this.number(0),
            amountPaid: this.number(0),
            dateDue: this.string('0000-00-00 00:00:00'),
        };
    }

}
