import { Fields, Model } from '@vuex-orm/core';
import SubscriptionAddon from './SubscriptionAddon';

export default class SubscriptionItem extends Model {
    public static modelName = 'SubscriptionItem';
    public static entity = 'subscription-items';

    public id: number;
    public addonId: number;
    public subscriptionId: number;
    public dateCreated: string;
    public dateStarted: string;
    public dateRemoved: string;
    public quantity: number;
    public amount: number;
    public total: number;
    public addon: SubscriptionAddon;
    public subscription: string;

    public static fields (): Fields {
        return {
            id: this.attr(0),
            addonId: this.attr(0),
            subscriptionId: this.attr(0),
            dateCreated: this.attr(''),
            dateStarted: this.attr(''),
            dateRemoved: this.attr(''),
            quantity: this.attr(0),
            amount: this.attr(0),
            total: this.attr(0),
            addon: this.hasOne(SubscriptionAddon, 'id', 'addonId'),
            subscription: this.attr(''),
        };
    }

}
