import {Fields, Model} from '@vuex-orm/core';

export default class AddOnV2 extends Model {
    public static modelName = 'AddOnV2';
    public static entity = 'addons/v2';

    public id!: number;
    public businessId!: number;
    public dateCreated!: string;
    public dateUpdated!: string;
    public name!: string;
    public description!: string;
    public token!: string;
    public amount!: number;
    public frequency!: string;
    public isQuantityEditable!: boolean;
    public quantity!: number;

    public static fields(): Fields {
        return {
            id: this.number(0),
            businessId: this.number(0),
            dateCreated: this.string('0000-00-00 00:00:00'),
            dateUpdated: this.string('0000-00-00 00:00:00'),
            name: this.string(''),
            description: this.string(''),
            token: this.string(''),
            amount: this.number(0.00),
            frequency: this.string(''),
            isQuantityEditable: this.boolean(false),
            quantity: this.number(0),
        };
    }
}