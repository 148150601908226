import type {
    DirectiveBinding,
    ObjectDirective,
} from 'vue';

type IntersectionHandler = (
    isIntersecting: boolean,
    entries: IntersectionObserverEntry[],
    observer: IntersectionObserver,
) => void

export interface IntersectDirectiveBinding extends Omit<DirectiveBinding, 'value'> {
    value?: IntersectionHandler | { handler: IntersectionHandler, options?: IntersectionObserverInit }
}

interface HiddenObserver {
    _observe: {
        intersect: IntersectionObserver
    },
}

function mounted (el: HTMLElement & HiddenObserver, binding: IntersectDirectiveBinding): void {
    const value = binding.value;
    const { handler, options } = typeof value === 'object'
        ? value
        : { handler: value, options: {} };

    const observer = new IntersectionObserver((
        entries: IntersectionObserverEntry[],
        observer: IntersectionObserver,
    ) => {
        if (!el._observe) {
            return;
        }

        const isIntersecting = entries.some(entry => entry.isIntersecting);

        if (handler) {
            handler(isIntersecting, entries, observer);
        }
    }, options);

    el._observe = { intersect: observer };

    observer.observe(el);
}

function unmounted (el: HTMLElement & HiddenObserver): void {
    if (!el._observe) {
        return;
    }

    el._observe.intersect.disconnect();
    delete el._observe;
}

const IntersectDirective: ObjectDirective<HTMLElement> = {
    mounted,
    unmounted,
};

export default IntersectDirective;