import {Fields, Model} from '@vuex-orm/core';
import PaymentPlanSettings from './PaymentPlanSettings';
import AddOnV2 from './AddOnV2';

export default class PaymentPlan extends Model {
    public static modelName = 'PaymentPlan';
    public static entity = 'payment-plans';

    public id!: number;
    public businessId!: number;
    public dateCreated!: string;
    public dateUpdated!: string;
    public userId!: number;
    public businessName!: string;
    public name!: string;
    public token!: string;
    public description!: string;
    public type!: number;
    public status!: number;
    public currency!: string;
    public cardTerminalId!: number;
    public merchantAccountId!: number;
    public setupAmount!: number;
    public billSetupImmediately!: number;
    public recurringAmount!: number;
    public billingPeriod!: number;
    public dateBilling!: string;
    public termType!: number;
    public freeTrialPeriod!: number;
    public taxType!: number;
    public billingPeriodIncrements!: number;
    public termLength!: number;
    public paymentMethod!: string;
    public defaultPaymentLinkId!: number;
    public periodName: string;
    public taxName: string;
    public taxRate: number;
    public paymentPlanSettings!: PaymentPlanSettings;
    public addOns: Record<number, AddOnV2>;

    public static fields(): Fields {
        return {
            id: this.number(0),
            businessId: this.number(0),
            dateCreated: this.string('0000-00-00 00:00:00'),
            dateUpdated: this.string('0000-00-00 00:00:00'),
            userId: this.number(0),
            businessName: this.string(''),
            name: this.string(''),
            token: this.string(''),
            description: this.string(''),
            type: this.number(0),
            status: this.number(0),
            currency: this.string(''),
            cardTerminalId: this.number(0),
            merchantAccountId: this.number(0),
            setupAmount: this.number(0),
            billSetupImmediately: this.number(0),
            recurringAmount: this.number(0),
            billingPeriod: this.number(0),
            dateBilling: this.string('0000-00-00 00:00:00'),
            termType: this.number(0),
            freeTrialPeriod: this.number(0),
            taxType: this.number(0),
            billingPeriodIncrements: this.number(0),
            termLength: this.number(0),
            paymentMethod: this.string(''),
            defaultPaymentLinkId: this.number(0),
            periodName: this.string(''),
            taxName: this.string(''),
            taxRate: this.number(0),
            paymentPlanSettings: this.attr({}),
            addOns: this.attr({}),
        };
    }
}