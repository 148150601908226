import {ICategory, Image} from '../../types';
import {htmlEntityDecode} from '../../models/utilities/ModelUtilities';
import Product from './Product';

const isCategory = (payload: unknown): payload is ICategory => {
    return payload !== undefined;
};

export default class Category implements ICategory {
    public id: number;
    public parentId: number;
    public merchantId: number;
    public lastUpdated: string;
    public name: string;
    public description: string;
    public availability: number;
    public availabilityOnline: number;
    public availabilityRetail: number;
    public backgroundColor: string;
    public featured: number;
    public images: Image[];
    public products: Product[];
    public orderCount: number;
    public size = 56;

    public constructor(category?: unknown) {
        if(!isCategory(category)) {
            return;
        }

        if(category.products && Array.isArray(category.products)) {
            this.products = category.products
                .sort(({name: first}, {name: last}) => {
                    return first.localeCompare(last);
                })
                .map(product => new Product(product));
        }

        this.id = category.id;
        this.parentId = category.parentId;
        this.merchantId = category.merchantId;
        this.lastUpdated = category.lastUpdated;
        this.name = htmlEntityDecode(category.name);
        this.description = htmlEntityDecode(category.description);
        this.availability = category.availability;
        this.availabilityOnline = category.availabilityOnline;
        this.availabilityRetail = category.availabilityRetail;
        this.backgroundColor = category.backgroundColor;
        this.featured = category.featured;
        this.images = category.images;
        this.orderCount = category.orderCount;
    }
}
