import {Fields, Model} from '@vuex-orm/core';
import TipRecommendations from './TipRecommendations';

export default class PaymentSettings extends Model {

    public static modelName = 'PaymentSettings';
    public static entity = 'payment-settings';

    public id: number;
    public dateCreated: string;
    public dateUpdated: string;
    public merchantId: number;
    public businessId: number;
    public taxLocation: number;
    public countryId: number;
    public provinceId: number;
    public tipMode: number;
    public enforceMaximumTip: boolean;
    public allowCustomTips: boolean;
    public tipRecommendType: number;
    public maximumTipType: number;
    public maximumTipAmount: number;
    public tipRecommendations: TipRecommendations;
    public tipRecommendSelected: number;
    public allowDiscountCodes: boolean;
    public transactionType: number;
    public allowNoPaymentCheckout: boolean;
    public invoiceType: number;


    public static fields(): Fields {
        return {

            id: this.number(0),
            dateCreated: this.string('00-00-00 00:00:00'),
            dateUpdated: this.string('00-00-00 00:00:00'),
            merchantId: this.number(0),
            businessId: this.number(0),
            taxLocation: this.number(0),
            countryId: this.number(0),
            provinceId: this.number(0),
            tipMode: this.number(0),
            enforceMaximumTip: this.boolean(false),
            allowCustomTips: this.boolean(false),
            tipRecommendType: this.number(0),
            maximumTipType: this.number(0),
            maximumTipAmount: this.number(0.00),
            tipRecommendations: this.attr({}),
            tipRecommendSelected: this.number(0),
            allowDiscountCodes: this.boolean(false),
            transactionType: this.number(0),
            allowNoPaymentCheckout: this.boolean(false),
            invoiceType: this.number(0),
        };
    }

}
