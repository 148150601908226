import {Fields, Model} from '@vuex-orm/core';

export default class CustomField extends Model {
    public static modelName = 'CustomField';
    public static entity = 'custom-fields';

    public id!: number;
    public groupId!: number;
    public merchantId!: number;
    public name!: string;
    public displayName!: string;
    public type!: number;
    public defaultValue!: string;
    public required!: number;
    public orderCount!: number;

    public static fields(): Fields {
        return {
             id: this.number(0),
             groupId: this.number(0),
             merchantId: this.number(0),
             name: this.string(''),
             displayName: this.string(''),
             type: this.number(0),
             defaultValue: this.string(''),
             required: this.number(0),
             orderCount: this.number(0),
        };
    }
}
