import {defineStore} from 'pinia';
import {useAxios} from '../compositions/utilities/useAxios';
import {AxiosResponse} from 'axios';
import {destructurePayload, hasProperty, isValidObject, logger} from './utilities';
import {computed, ref} from 'vue';
import Cart from './classes/Cart';
import {Address, CartTotal, ICartItem, PickupTime} from '../types';
import CartItem from './classes/CartItem';
import {useSettings} from '../compositions';
import Product from './classes/Product';

export const useCartStore = defineStore('cart-store', () => {
    const { axiosGet, axiosPost, axiosPut, axiosDelete } = useAxios();
    const { goSettings } = useSettings();

    const cart = ref<Cart>(null);
    const cartItems = ref(new Map<number, CartItem>([]));
    const cartItemsByProduct = computed(() => {
        const map = new Map<number, CartItem>([]);
        Array.from(cartItems.value.values())
            .forEach(v => {
                if(v.isClaimedItem) {
                    return;
                }
                map.set(v.offeringId, v);
            });
        return map;
    });

    const pickupTimes = ref(new Map<number, PickupTime>([]));
    
    const cartTotal = computed<CartTotal>(() => {
        if(!cart.value) {
            return null;
        }
        return cart.value.cartTotal;
    });
    
    const billingAddress = computed<Address>(() => {
        if(!cart.value) {
            return null;
        }
        if(!cart.value.billingAddressId) {
            return null;
        }
        return cart.value.billingAddress;
    });

    const shippingAddress = computed<Address>(() => {
        if(!cart.value) {
            return null;
        }
        if(!cart.value.shippingAddressId) {
            return null;
        }
        return cart.value.shippingAddress;
    });

    const cartItemQuantities = computed(() => {
        const map = new Map<number, number>([]);
        Array.from(cartItems.value.values())
            .forEach(v => {
                if(v.isClaimedItem) {
                    return;
                }
                map.set(v.offeringId, (map.get(v.offeringId) ?? 0) + v.quantity);
            });
        return map;
    });

    const registerCart = (payload: unknown) => {
        cart.value = new Cart(payload);
    };

    const registerCartItems = (payload: unknown[]) => {
        cartItems.value.clear();
        payload.forEach(v => {
            const cartItem = new CartItem(v);
            cartItems.value.set(cartItem.id, cartItem);
        });
    };

    const isPickupTime = (value: unknown): value is PickupTime => {
        return hasProperty(value, 'id') && typeof value.id === 'number'
            && hasProperty(value, 'time') && value.time === 'string';
    };

    const registerPickupTimes = (payload: Record<string, unknown>[]) => {
        payload.forEach(v => {
            if(isPickupTime(v)) {
                pickupTimes.value.set(v.id, v);
            }
        });
    };

    const getCart = () => {
        return axiosGet<AxiosResponse>('/cart')
            .then((response) => {
                if(!isValidObject(response)) {
                    throw new Error('invalid object');
                }
                if(!response.id) {
                    logger.debug('[cart-store]: no cart found');
                    return false;
                }
                registerCart(response);
                return true;
            });
    };

    const getCartItems = () => {
        return axiosGet<AxiosResponse & { items: unknown, CartTotal: unknown }>('/cart-items')
            .then((response) => {
                if(Array.isArray(response.items) && !response.items.length) {
                    logger.debug('[cart-store]: no cart or cart is empty');
                    return false;
                }
                if(!isValidObject(response.items)) {
                    throw new Error('Invalid response.');
                }
                const payload = destructurePayload(response.items);
                registerCartItems(payload);
                return true;
            });
    };

    const getPickupTimes = () => {
        if(!goSettings?.value?.pickupEnabled) {
            return;
        }
        axiosGet<AxiosResponse & { items: unknown }>('/pickup')
            .then((response) => {
                if(!isValidObject(response.items)) {
                    throw new Error('Invalid response.');
                }
                const payload = destructurePayload(response.items);
                registerPickupTimes(payload);
            })
            .catch(reason => {
                logger.warn('Failed to fetch products.', reason);
            });
    };

    const initCart = async () => {
        await Promise.all([
            getCart(),
            getCartItems(),
        ]);
    };

    const resetCart = () => {
        cart.value = null;
        cartItems.value.clear();
    };

    interface CartItemResponse {
        CartTotal: CartTotal,
        CartItem: ICartItem,
    }

    const updateQuantity = async (productId: number, quantity: number) => {
        if(quantity === 0) {
            return await axiosDelete<AxiosResponse & [CartItemResponse]>(`/cart-items/${cartItemsByProduct.value.get(productId).id}`)
                .then(async (response) => {
                    cartItems.value.delete(cartItemsByProduct.value.get(productId).id);
                    cart.value.cartTotal = response[0].CartTotal;
                    return 0;
                })
                .catch()
                .finally();
        }
        if(cartItemsByProduct.value.has(productId)) {
            return await axiosPut<AxiosResponse & [CartItemResponse]>(`/cart-items/${cartItemsByProduct.value.get(productId).id}`, {
                quantity: quantity,
            })
                .then(async (response) => {
                    const cartItem = new CartItem(response[0].CartItem);
                    cartItems.value.set(cartItem.id, cartItem);
                    cart.value.cartTotal = response[0].CartTotal;
                    return cartItem.quantity;
                })
                .catch()
                .finally();
        }
        showOverlay();
        return await axiosPost<AxiosResponse & [CartItemResponse]>('/cart-items/create', {
            productId: productId,
            quantity: quantity,
        })
            .then(async (response) => {
                const cartItem = new CartItem(response[0].CartItem);
                if (!cart.value) {
                    await initCart();
                } else {
                    cartItems.value.set(cartItem.id, cartItem);
                    cart.value.cartTotal = response[0].CartTotal;
                }
                return cartItem.quantity;
            })
            .catch()
            .finally(hideOverlay);
    };

    const addToCart = async (
        product: Product,
        quantity: number,
        addonsArray: never[],
        optionsArray: never[],
        notes = '',
    ) => {
        showOverlay();
        if(quantity === 0) {
            return;
        }
        const requestParams = {
            'quantity': quantity,
            'productId': product.id,
            'optionChoices': optionsArray,
            'addons': addonsArray,
            'note': notes,
            'currencyId': cart.value ? cart.value.currencyId : 0,
        };
        return axiosPost<AxiosResponse & [CartItemResponse]>('/cart-items/create', requestParams)
            .then(async (response) => {
                const cartItem = new CartItem(response[0].CartItem);
                if (!cart.value) {
                    await initCart();
                } else {
                    cartItems.value.set(cartItem.id, cartItem);
                    cart.value.cartTotal = response[0].CartTotal;
                }
                hideOverlay();
                return Promise.resolve();
            }).catch(e => {
                return Promise.reject(e);
            });
    };

    function showOverlay() {
        if(!cart.value) {
            const quickOrderList = document.querySelector('#full-product-list');
            quickOrderList.classList.add('disable-quick-order-list');
        }
    }

    function hideOverlay() {
        const quickOrderList = document.querySelector('#full-product-list');
        if(!(quickOrderList instanceof HTMLElement)) {
            return;
        }
        quickOrderList.classList.remove('disable-quick-order-list');
    }

    return {
        initCart,
        getPickupTimes,
        resetCart,
        updateQuantity,
        addToCart,
        hideOverlay,
        cart,
        cartItems,
        cartItemQuantities,
        pickupTimes,
        cartTotal,
        billingAddress,
        shippingAddress,
    };
});
