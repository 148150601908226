import {Fields, Model} from '@vuex-orm/core';

export default class Terminal extends Model {
    public static modelName = 'Terminal';
    public static entity = 'terminals';

    public id!: string;
    public merchantId!: string;
    public addressId!: string;
    public directAmex!: string;
    public directVisa!: string;
    public directMaster!: string;
    public directDiscover!: string;
    public currency!: string;
    public multiCurrency!: string;
    public eft: string;
    public status!: string;
    public locked!: string;
    public nickname!: string;
    public merchantType!: string;
    public batchSettleAuto!: string;
    public batchSettleTime!: string;
    public allowSettleEdit!: string;
    public acquirer!: string;
    public acquirerTest!: string;
    public acquirerField1!: string;
    public acquirerField2!: string;
    public acquirerField3!: string;
    public acquirerField4!: string;
    public acquirerField5!: string;
    public acquirerField6!: string;
    public acquirerField7!: string;
    public acquirerField8!: string;
    public acquirerField9!: string;
    public acquirerField10!: string;
    public acceptedCardTypes!: string;
    public acceptedCardImages!: string;
    public level3!: string;
    public merchantCategoryCode!: string;


    public static fields(): Fields {
        return {
            id: this.string(''),
            merchantId: this.string(''),
            addressId: this.string(''),
            directAmex: this.string(''),
            directVisa: this.string(''),
            directMaster: this.string(''),
            directDiscover: this.string(''),
            currency: this.string(''),
            multiCurrency: this.string(''),
            eft: this.string(''),
            status: this.string(''),
            locked: this.string(''),
            nickname: this.string(''),
            merchantType: this.string(''),
            batchSettleAuto: this.string(''),
            batchSettleTime: this.string(''),
            allowSettleEdit: this.string(''),
            acquirer: this.string(''),
            acquirerTest: this.string(''),
            acquirerField1: this.string(''),
            acquirerField2: this.string(''),
            acquirerField3: this.string(''),
            acquirerField4: this.string(''),
            acquirerField5: this.string(''),
            acquirerField6: this.string(''),
            acquirerField7: this.string(''),
            acquirerField8: this.string(''),
            acquirerField9: this.string(''),
            acquirerField10: this.string(''),
            acceptedCardTypes: this.string(''),
            acceptedCardImages: this.string(''),
            level3: this.string(''),
            merchantCategoryCode: this.string(''),
        };
    }
}
