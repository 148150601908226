import { Fields, Model } from '@vuex-orm/core';

export default class SubscriptionAddon extends Model {
    public static modelName = 'SubscriptionAddon';
    public static entity = 'subscription-addons';

    public id: number;
    public merchantId: number;
    public recurringId: number;
    public name: string;
    public code: string;
    public amount: number;
    public type: number;
    public quantity: number;
    public selectable: number;

    public static fields (): Fields {
        return {
            id: this.attr(0),
            merchantId: this.attr(0),
            recurringId: this.attr(0),
            name: this.attr(''),
            code: this.attr(''),
            amount: this.attr(0.0),
            type: this.attr(0),
            quantity: this.attr(0),
            selectable: this.attr(0),
        };
    }

}
