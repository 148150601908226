import Address from './Address';
import Customer from './Customer';
import OrderItem from './OrderItem';
import RecurringPlan from './RecurringPlan';
import OrderField from './OrderField';
import Deposit from './Deposit';
import Transaction from './Transaction';
import TransactionEFT from './TransactionEFT';
import TransactionOther from './TransactionOther';
import {Fields, Model} from '@vuex-orm/core';
import QuoteApproval from './QuoteApproval';

export default class Order extends Model {
    public static modelName = 'Order';
    public static entity = 'orders';

    public id!: number;
    public merchantId!: number;
    public orderNumber?: string;
    public token?: string;
    public dateCreated?: string;
    public dateIssued?: string;
    public datePaid?: string;
    public createdBy?: string; //TODO: number, commerce will be passing the user name
    public currency?: number;
    public type?: number;
    public status?: number;
    public paymentTerms?: number;
    public customerId!: number;
    public billingAddressId!: number;
    public shippingAddressId!: number;
    public amount!: number;
    public amountDiscount!: number;
    public amountShipping!: number;
    public amountTax!: number;
    public amountTip!: number;
    public amountDeposit!: number;
    public amountPaid!: number;
    public comments?: string;
    public discountDetails?: string;
    public shippingMethod?: string;
    public taxDetails?: string;
    public poNumber?: string;
    public subscriptionId!: number;
    public extraData?: Record<string, number>;
    public createdPlatform?: number;
    public customer?: Customer;
    public items?: OrderItem[];
    public deposit?: Deposit;
    public quoteApproval?: QuoteApproval;
    public transactions?: Transaction[];
    public transactionEFTs?: TransactionEFT[];
    public transactionOthers?: TransactionOther[];
    public billingAddress?: Address;
    public shippingAddress?: Address;
    public recurringPlan?: RecurringPlan;
    public orderFields?: OrderField[];
    //Temp properties:
    public recurringPlanId!: number;
    public pseudoStatus!: number;

    public static fields(): Fields {
        return {
            id: this.number(0),
            merchantId: this.number(0),
            orderNumber: this.string(''),
            token: this.string(''),
            dateCreated: this.string('0000-00-00 00:00:00'),
            dateIssued: this.string('0000-00-00 00:00:00'),
            datePaid: this.string('0000-00-00 00:00:00'),
            createdBy: this.string(''),
            currency: this.number(0),
            type: this.number(0),
            status: this.number(0),
            paymentTerms: this.number(0),
            customerId: this.number(0),
            billingAddressId: this.number(0),
            shippingAddressId: this.number(0),
            amount: this.number(0),
            amountDiscount: this.number(0),
            amountShipping: this.number(0),
            amountTax: this.number(0),
            amountTip: this.number(0),
            amountDeposit: this.number(0),
            amountPaid: this.number(0),
            comments: this.string(''),
            discountDetails: this.string(''),
            shippingMethod: this.string(''),
            taxDetails: this.string(''),
            poNumber: this.string(''),
            subscriptionId: this.number(0),
            extraData: this.attr({}),
            createdPlatform: this.number(0),
            customer: this.hasOne(Customer, 'id', 'customerId'),
            items: this.hasMany(OrderItem, 'orderId'),
            deposit: this.hasOne(Deposit, 'invoiceId'),
            quoteApproval: this.hasOne(QuoteApproval, 'invoiceId'),
            transactions: this.hasMany(Transaction, 'orderId'),
            transactionEFTs: this.hasMany(TransactionEFT, 'orderId'),
            transactionOthers: this.hasMany(TransactionOther, 'orderId'),
            billingAddress: this.hasOne(Address, 'id', 'billingAddressId'),
            shippingAddress: this.hasOne(Address, 'id', 'shippingAddressId'),
            recurringPlan: this.hasOne(RecurringPlan, 'recurringInvoiceId', 'id'),
            orderFields: this.hasMany(OrderField, 'orderId'),
            recurringPlanId: this.number(0),
            pseudoStatus: this.number(0),

        };
    }
}
