import {Fields, Model} from '@vuex-orm/core';

export default class RegistrationRequest extends Model {
    public static modelName = 'RegistrationRequest';
    public static entity = 'registrationRequests';

    public fullName!: string;
    public email!: string;
    public billingAddress!: string;
    public shippingAddress!: string;
    public cardInfo!: string;
    public fromCheckout!: string;
    public fromStore!: string;

    public static fields (): Fields {
        return {
            fullName: this.string(''),
            email: this.string(''),
            billingAddress: this.string(''),
            shippingAddress: this.string(''),
            cardInfo: this.string(''),
            fromCheckout: this.string(''),
            fromStore: this.string(''),
        };
    }
}
