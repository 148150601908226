import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
const SubscriptionsV2 = () => import('../views/dashboard/subscriptionsV2/SubscriptionsV2.vue');
const SubscriptionV2Overview = () => import('../views/dashboard/subscriptionsV2/SubscriptionV2Overview.vue');
const StoreView = () => import('../views/StoreView.vue');
const Checkout = () => import('../views/checkout/Checkout.vue');
const CheckoutLogin = () => import('../views/checkout/CheckoutLogin.vue');
const PortalView = () => import('../views/portal/PortalView.vue');
const ReviewOrder = () => import('../views/review-order/ReviewOrder.vue');
const OrderHistory = () => import('../views/dashboard/OrderHistory.vue');
const Dashboard = () => import('../views/dashboard/Dashboard.vue');
const DashboardView = () => import('../views/dashboard/DashboardView.vue');
const Subscriptions = () => import('../views/dashboard/subscriptions/Subscriptions.vue');
const SubscriptionOverview = () => import('../views/dashboard/subscriptions/SubscriptionOverview.vue');
const CustomerProfile = () => import('../views/dashboard/customer/CustomerProfile.vue');
const CustomerAddresses = () => import('../views/dashboard/customer/CustomerAddresses.vue');
const CustomerPayments = () => import('../views/dashboard/customer/CustomerPayments.vue');

const routes: RouteRecordRaw[] = [
    {
        path: '/quick-order',
        name: 'QuickOrder',
        component: StoreView,
    },
    {
        path: '/review-order',
        name: 'ReviewOrder',
        component: ReviewOrder,
    },
    {
        path: '/checkout-login',
        name: 'CheckoutLogin',
        component: CheckoutLogin,
    },
    {
        path: '/checkout',
        name: 'Checkout',
        component: Checkout,
    },
    {
        path: '/login',
        name: 'PortalView',
        component: PortalView,
    },
    {
        path: '/register',
        redirect: '/dashboard',
    },
    {
        path: '/password-reset',
        redirect: '/login',
    },
    {
        path: '/dashboard',
        name: 'DashboardView',
        redirect: '/dashboard/main',
        component: DashboardView,
        children: [
            {
                path: 'main',
                name: 'Dashboard',
                component: Dashboard,
            },
            {
                path: 'order-history',
                name: 'Order History',
                component: OrderHistory,
            },
            {
                path: 'subscriptions',
                name: 'Subscriptions',
                component: Subscriptions,
            },
            {
                path: 'subscriptions/v2',
                name: 'SubscriptionsV2',
                component: SubscriptionsV2,
            },
            {
                path: 'subscriptions/:id(\\d+)',
                name: 'Subscription Overview',
                component: SubscriptionOverview,
            },
            {
                path: 'subscriptions/v2/:id(\\d+)',
                name: 'SubscriptionV2 Overview',
                component: SubscriptionV2Overview,
            },
            {
                path: 'profile',
                name: 'Profile',
                component: CustomerProfile,
            },
            {
                path: 'addresses',
                name: 'Addresses',
                component: CustomerAddresses,
            },
            {
                path: 'payments',
                name: 'Payments',
                component: CustomerPayments,
            },
        ],
    },
    {
        path: '/:catchAll(.*)',
        redirect: '/quick-order',
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;

