<template>
    <template v-if="router.currentRoute.value.fullPath.includes('/dashboard')">
        <RouterView/>
    </template>
    <div v-else-if="isComplete" class="wrapper">
        <Button
            v-if="$route.name === 'Login' && goSettings.onlineStoreActive"
            class="go-to-store bg-transparent"
            icon="far fa-arrow-left"
            text="Back to Online Store"
            @click="redirectToQuickOrder"/>
        <div v-else class="flex-column">
            <RouterView/>
        </div>
    </div>
    <div v-else class="flex-center p-24 vh-100 vw-100">
        <Loading text="Please wait while we prepare the store for you"/>
    </div>
</template>

<script lang="ts" setup>
import {onMounted, ref, watch} from 'vue';
import { Button } from '@helcim/blizzard';
import {useActors, useAsyncCall, useSettings} from './compositions';
import { useOrm } from './compositions/utilities/useOrm';
import { useAxios } from './compositions/utilities/useAxios';
import { LINKS } from './scripts/constants';
import {useRoute, useRouter} from 'vue-router';
import Loading from './components/general/Loading.vue';
import {useStore} from 'vuex';
import ShippingMethodOption from './models/ShippingMethodOption';
import {useDocumentStyle} from './compositions';
import {useProductStore} from './store/products';
import {useCartStore} from './store/cart';
import Cart from './models/Cart';
import CartItem from './models/CartItem';


const route = useRoute();
const router = useRouter();
const store = useStore();

const productStore = useProductStore();
const cartStore = useCartStore();

const { axiosGetIntoStore } = useAxios(store);
const { setDocumentStyle } = useDocumentStyle();
const { goSettings, portalSettings } = useSettings();
const { customer } = useActors();
const { getAll: getAllShippingOptions } = useOrm(ShippingMethodOption);
const { complete } = useAsyncCall(loadEnvironment());
const { get: getCart } = useOrm(Cart);
const { getAll: getAllCartItems } = useOrm(CartItem);


const isComplete = ref(false);

const redirectToQuickOrder = () => {
    router.push(LINKS.QUICK_ORDER);
};

const onComplete = async () => {
    setDocumentStyle();
    const storeLoginRequired = goSettings.value?.requireStoreLogin && !customer.value?.id;
    if(portalSettings?.value.enabled) {
        if((storeLoginRequired || !goSettings.value?.onlineStoreActive) && ['QuickOrder', 'ReviewOrder', 'CheckoutLogin', 'Checkout'].includes(route.name as string)) {
            await router.push(LINKS.LOGIN);
        }
    }
    isComplete.value = true;
};

watch(complete, onComplete);

async function loadEnvironment() {
    await ShippingMethodOption.deleteAll();
    await Promise.all([
        axiosGetIntoStore('/settings', 'settings/init'),
        axiosGetIntoStore('/actors', 'actors/init'),
        axiosGetIntoStore('/settings/social-login', 'socialLogin/init'),
    ]);
    if(goSettings.value?.onlineStoreActive) {
        await Promise.allSettled([
            axiosGetIntoStore('/settings/text-blocks', 'textBlocks/init'),
            axiosGetIntoStore('/settings/store-hours', 'storeHours/init'),
            getAllShippingOptions(),
            getCart('/cart'),
            getAllCartItems(),
            productStore.initProducts(),
            cartStore.initCart(),
        ]);
    }
}
onMounted(() => isComplete.value = false);
</script>
