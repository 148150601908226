import {Fields, Model} from '@vuex-orm/core';

export default class ThemeDesignSettings extends Model {

    public static modelName = 'ThemeDesignSettings';
    public static entity = 'theme-design-settings';

    public enableStoreDetails: boolean;
    public showProductSKU: boolean;
    public showProductStockAvailability: boolean;
    public productOrdering: number;
    public logoImageId: number;
    public iconImageId: number;
    public logoWidth: string;
    public productPhotoWidth: string;
    public objectFit: string;
    public brandColor: string;
    public themeMode: string;
    public fontFace: string;
    public fontSize: number;
    public banner: string;

    public static fields(): Fields {
        return {
            enableStoreDetails: this.boolean(false),
            showProductSKU: this.boolean(false),
            showProductStockAvailability: this.boolean(false),
            productOrdering: this.number(0),
            logoImageId: this.number(0),
            iconImageId: this.number(0),
            logoWidth: this.number(''),
            productPhotoWidth: this.string(''),
            objectFit: this.string(''),
            brandColor: this.string(''),
            themeMode: this.string(''),
            fontFace: this.string(''),
            fontSize: this.number(0),
            banner: this.string(''),
        };
    }
}