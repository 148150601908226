import {Fields, Model} from '@vuex-orm/core';

export default class TipRecommendations extends Model {

    public static modelName = 'TipRecommendations';
    public static entity = 'tip-recommendations';

    public tipRecommendationOne: number;
    public tipRecommendationTwo: number;
    public tipRecommendationThree: number;

    public static fields(): Fields {
        return {
            tipRecommendationOne: this.number(0),
            tipRecommendationTwo: this.number(0),
            tipRecommendationThree: this.number(0),
        };
    }

}