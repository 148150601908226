import {Fields, Model} from '@vuex-orm/core';

export default class StoreStyles extends Model {

    public static modelName = 'StoreStyles';
    public static entity = 'store-styles';

    public id: number;
    public dateCreated: string;
    public dateUpdated: string;
    public merchantId: string;
    public iconImageId: number;
    public logoImageId: number;
    public logoWidth: number;
    public productPhotoWidth: string;
    public objectFit: string;
    public brandColor: string;
    public themeMode: string;
    public fontFace: string;
    public fontSize: number;
    public banner: string;

    public static fields(): Fields {
        return {
            id: this.number(0),
            dateCreated: this.string('0000-00-00 00:00:00'),
            dateUpdated: this.string('0000-00-00 00:00:00'),
            merchantId: this.string(''),
            iconImageId: this.number(0),
            logoImageId: this.number(0),
            logoWidth: this.number(0),
            productPhotoWidth: this.string(''),
            objectFit: this.string(''),
            brandColor: this.string(''),
            themeMode: this.string(''),
            fontFace: this.string(''),
            fontSize: this.number(0),
            banner: this.string(''),
        };
    }

}
