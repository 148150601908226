import {Fields, Model} from '@vuex-orm/core';

export default class InventorySetting extends Model {
    public static modelName = 'InventorySetting';
    public static entity = 'inventory-settings';

    public id: number;
    public merchantId: number;
    public lowStockWarning: number;
    public lowStockThreshold: number;
    public onlineStoreUpdate: number;
    public onlineStoreBackorder: number;
    public payPageView: number;
    public payPageUpdate: number;
    public payPageBackorder: number;
    public orderView: number;
    public orderUpdate: number;
    public orderBackorder: number;
    public retailView: number;
    public retailUpdate: number;
    public retailBackorder: number;
    public replenishOnRefund: number;
    public settingsTrackByOrderType: number;

    public static fields(): Fields {
        return {
            id: this.number(0),
            merchantId: this.number(0),
            lowStockWarning: this.number(0),
            lowStockThreshold: this.number(0),
            onlineStoreUpdate: this.number(0),
            onlineStoreBackorder: this.number(0),
            payPageView: this.number(0),
            payPageUpdate: this.number(0),
            payPageBackorder: this.number(0),
            orderView: this.number(0),
            orderUpdate: this.number(0),
            orderBackorder: this.number(0),
            retailView: this.number(0),
            retailUpdate: this.number(0),
            retailBackorder: this.number(0),
            replenishOnRefund: this.number(0),
            settingsTrackByOrderType: this.number(0),
        };
    }
}
