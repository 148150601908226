import {Model} from '@vuex-orm/core';
import PaymentPlan from './PaymentPlan';
import AddOnV2 from './AddOnV2';
import SubscriptionAddOnV2 from './SubscriptionAddOnV2';

export default class SubscriptionV2 extends Model {
    public static modelName = 'SubscriptionV2';
    public static entity = 'subscriptions/v2';

    public id!: number;
    public businessId!: number;
    public dateCreated!: string;
    public dateUpdated!: string;
    public dateActivated!: string;
    public userId!: number;
    public status!: number;
    public paymentPlanId!: number;
    public paymentMethodId!: number;
    public contactName!: string;
    public customerId!: number;
    public dateBilling!: string;
    public timesBilled!: number;
    public recurringAmount!: number;
    public freeTrialPeriod!: number;
    public contactPhoneNumber!: string;
    public hasFailedPayments!: boolean;
    public paymentPlan!: PaymentPlan;
    public addOns!: Record<number, AddOnV2>;
    public subscriptionAddOns!: SubscriptionAddOnV2[];

    public static fields() {
        return {
            id: this.number(0),
            businessId: this.number(0),
            dateCreated: this.string('0000-00-00 00:00:00'),
            dateUpdated: this.string('0000-00-00 00:00:00'),
            dateActivated: this.string('0000-00-00 00:00:00'),
            userId: this.number(0),
            status: this.number(0),
            paymentPlanId: this.number(0),
            paymentMethodId: this.number(0),
            contactName: this.string(''),
            customerId: this.number(0),
            dateBilling: this.string('0000-00-00 00:00:00'),
            timesBilled: this.number(0),
            recurringAmount: this.number(0),
            freeTrialPeriod: this.number(0),
            contactPhoneNumber: this.string(''),
            hasFailedPayments: this.boolean(false),
            paymentPlan: this.hasOne(PaymentPlan, 'id', 'paymentPlanId'),
            addOns: this.attr({}),
            subscriptionAddOns: this.hasMany(SubscriptionAddOnV2, 'subscriptionId', 'id'),
        };
    }

}