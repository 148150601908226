import {Fields, Model} from '@vuex-orm/core';
import RecurringAddon from './RecurringAddon';

export default class RecurringPlan extends Model {
    public static modelName = 'RecurringPlan';
    public static entity = 'recurring-plans';

    public id!: number;
    public merchantId!: number;
    public recurringInvoiceId!: number;
    public dateCreated!: string;
    public dateStarted!: string;
    public dateLastBilled!: string;
    public dateNextBilling!: string;
    public terminalId!: number;
    public terminalEFTId!: number;
    public currency!: number;
    public status!: number;
    public name!: string;
    public code!: string;
    public token!: string;
    public description!: string;
    public cyclesCount!: number;
    public period!: string;
    public trialPeriodDays!: number;
    public trialAutoSubscribe!: number;
    public amountInitial!: number;
    public billOn!: number;
    public amountRecurring!: number;
    public taxable!: number;
    public taxLocation!: number;
    public billingMethod!: number;
    public showInCustomerPortal!: number;
    public editable!: number;
    public cancelable!: number;
    public automaticRecurring!: number;
    public recurringAddOns: RecurringAddon[];
    public periodName: string;

    public static fields(): Fields {
        return {
            id: this.number(0),
            merchantId: this.number(0),
            recurringInvoiceId: this.number(0),
            dateCreated: this.string('0000-00-00 00:00:00'),
            dateStarted: this.string('0000-00-00 00:00:00'),
            dateLastBilled: this.string('0000-00-00 00:00:00'),
            dateNextBilling: this.string('0000-00-00 00:00:00'),
            terminalId: this.number(0),
            terminalEFTId: this.number(0),
            currency: this.number(0),
            status: this.number(0),
            name: this.string(''),
            code: this.string(''),
            token: this.string(''),
            description: this.string(''),
            cyclesCount: this.number(0),
            period: this.string(''),
            trialPeriodDays: this.number(0),
            trialAutoSubscribe: this.number(0),
            amountInitial: this.number(0),
            billOn: this.number(0),
            amountRecurring: this.number(0),
            taxable: this.number(0),
            taxLocation: this.number(0),
            billingMethod: this.number(0),
            showInCustomerPortal: this.number(0),
            editable: this.number(0),
            cancelable: this.number(0),
            automaticRecurring: this.number(0),
            recurringAddOns: this.hasMany(RecurringAddon, 'recurringId', 'id'),
            periodName: this.string(''),
        };
    }
}
