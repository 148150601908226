import {
    BuyGetPromotion, Image,
    IProduct,
    ProductAddon,
    ProductOption,
    ProductPriceRange,
    ProductPricing,
    ProductVariant,
} from '../../types';
import {htmlEntityDecode} from '../../models/utilities/ModelUtilities';

const isProduct = (payload: unknown): payload is IProduct => {
    return payload !== undefined;
};

export default class Product implements IProduct {
    public id: number;
    public merchantId: number;
    public lastUpdated: string;
    public categoryId: number;
    public brandId: number;
    public name: string;
    public description: string;
    public sku: string;
    public barcode: string;
    public availability: number;
    public availabilityOnline: number;
    public availabilityRetail: number;
    public featured: number;
    public stock: number;
    public stockType: number;
    public price: number;
    public salePrice: number;
    public wholesalePrice: number;
    public enableWholesale: number;
    public cost: number;
    public deposit: number;
    public dateCreated: string;
    public weight: number;
    public dimensionW: number;
    public dimensionL: number;
    public dimensionH: number;
    public shippingExempt: number;
    public taxExempt: number;
    public variantStatus: number;
    public backgroundColor: string;
    public trackInventory: number;
    public lowStockThreshold: number;
    public unitOfMeasure: string;
    public commodityCode: string;
    public seoURL: string;
    public seoDescription: string;
    public seoTags: string;
    public reviewScore: number;
    public productVariants: ProductVariant[];
    public productPricing: ProductPricing;
    public productPriceRanges: ProductPriceRange[];
    public buyGetPromotion: BuyGetPromotion;
    public productOptions: ProductOption[];
    public productAddons: ProductAddon[];
    public images: Image[];

    public constructor(product?: unknown) {
        if(!isProduct(product)) {
            return;
        }

        this.id = product.id;
        this.merchantId = product.merchantId;
        this.lastUpdated = product.lastUpdated;
        this.categoryId = product.categoryId;
        this.brandId = product.brandId;
        this.name = htmlEntityDecode(product.name);
        this.description = htmlEntityDecode(product.description);
        this.sku = product.sku;
        this.barcode = product.barcode;
        this.availability = product.availability;
        this.availabilityOnline = product.availabilityOnline;
        this.availabilityRetail = product.availabilityRetail;
        this.featured = product.featured;
        this.stock = product.stock;
        this.stockType = product.stockType;
        this.price = product.price;
        this.salePrice = product.salePrice;
        this.wholesalePrice = product.wholesalePrice;
        this.enableWholesale = product.enableWholesale;
        this.cost = product.cost;
        this.deposit = product.deposit;
        this.dateCreated = product.dateCreated;
        this.weight = product.weight;
        this.dimensionW = product.dimensionW;
        this.dimensionL = product.dimensionL;
        this.dimensionH = product.dimensionH;
        this.shippingExempt = product.shippingExempt;
        this.taxExempt = product.taxExempt;
        this.variantStatus = product.variantStatus;
        this.backgroundColor = product.backgroundColor;
        this.trackInventory = product.trackInventory;
        this.lowStockThreshold = product.lowStockThreshold;
        this.unitOfMeasure = product.unitOfMeasure;
        this.commodityCode = product.commodityCode;
        this.seoURL = product.seoURL;
        this.seoDescription = product.seoDescription;
        this.seoTags = product.seoTags;
        this.reviewScore = product.reviewScore;
        this.productVariants = product.productVariants;
        this.productPricing = product.productPricing;
        this.productPriceRanges = product.productPriceRanges;
        this.buyGetPromotion = product.buyGetPromotion;
        this.productOptions = product.productOptions;
        this.productAddons = product.productAddons;
        this.images = product.images;
    }
}
