import {Fields, Model} from '@vuex-orm/core';

export default class ShippingMethodRange extends Model {
    
    public static modelName = 'ShippingMethodRange';
    public static entity = 'shipping-method-range';

    public id: number;
    public shippingMethodId: number;
    public dateCreated: string;
    public dateUpdated: string;
    public rangeFrom: number;
    public rangeTo: number;
    public shippingAmount: number;

    public static fields(): Fields {
        return {
            id: this.number(0),
            shippingMethodId: this.number(0),
            dateCreated: this.string('0000-00-00 00:00:00'),
            dateUpdated: this.string('0000-00-00 00:00:00'),
            rangeFrom: this.number(0.0),
            rangeTo: this.number(0.0),
            shippingAmount: this.number(0.0),
        };
    }
}