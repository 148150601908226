import {Fields, Model} from '@vuex-orm/core';

export default class TransactionEFT extends Model {
    public static modelName = 'TransactionEFT';
    public static entity = 'transaction-efts';

    public id: number;
    public merchantId: number;
    public orderId: number;
    public dateCreated: string;
    public statusAuth: number;
    public statusClearing: number;
    public userId: number;
    public batchId: number;
    public bankAccountId: number;
    public bankAccountL4L4: string;
    public transactionType: number;
    public amount: number;
    public currency: number;
    public approvalCode: string;
    public test: number;
    public acquirerTransactionId: string;
    public responseMessage?: string;
    public payFacTransactionId?: number;
    public originalTransactionId?: number;
    public statusBatch?: number;
    public dateClosed: string;

    public static fields(): Fields {
        return {
            id: this.number(0),
            merchantId: this.number(0),
            orderId: this.number(0),
            dateCreated: this.string('0000-00-00 00:00:00'),
            statusAuth: this.number(0),
            statusClearing: this.number(0),
            userId: this.number(0),
            batchId: this.number(0),
            bankAccountId: this.number(0),
            bankAccountL4L4: this.string(''),
            transactionType: this.number(0),
            amount: this.number(0),
            currency: this.number(0),
            approvalCode: this.string(''),
            test: this.number(0),
            acquirerTransactionId: this.string(''),
            responseMessage: this.string(''),
            payFacTransactionId: this.number(0),
            originalTransactionId: this.number(0),
            statusBatch: this.number(0),
            dateClosed: this.string(''),
        };
    }
}
