import {Store, useStore} from 'vuex';
import {
    GoSettings,
    PaymentSettings,
    VisualSettings,
    Settings,
    GeneralSetting,
    PortalSettings,
    PortalThemeSettings,
} from '../types';
import {computed} from 'vue';
import InventorySetting from '../models/InventorySetting';
import SettingsProgress from '../models/SettingsProgress';
import {
    BRAND_THEMES,
    COLOUR_LEGACY_TO_NEW_MAPPING,
    COLOUR_NEW_TO_LEGACY_MAPPING,
    LEGACY_BRAND_THEMES,
    LINKS,
} from '../scripts/constants';


export function useSettings() {

    const store: Store<Settings> = useStore();

    const paymentSettings = computed<PaymentSettings>(() => store.getters['settings/payment']);
    const visualSettings = computed<VisualSettings>(() => store.getters['settings/visual']);
    const goSettings = computed<GoSettings>(() => store.getters['settings/go']);
    const inventorySetting = computed<InventorySetting>(() => store.getters['settings/inventory']);
    const settingsProgress = computed<SettingsProgress>(() => store.getters['settings/progress']);
    const generalSetting = computed<GeneralSetting>(() => store.getters['settings/general']);
    const portalSettings = computed<PortalSettings>(() => store.getters['settings/portal']);
    const portalThemeSettings = computed<PortalThemeSettings>(() => store.getters['settings/portalTheme']);

    function deepCopyOf<T>(setting: T): T {
        return JSON.parse(JSON.stringify(setting));
    }
    interface T {
        $id: number
    }
    function objectHasChanged<S>(initialObject: T | S, currentObject: T | S, sortProperties = false): boolean {
        if ('$id' in initialObject) {
            delete initialObject.$id;
        }
        if ('$id' in currentObject) {
            delete currentObject.$id;
        }
        return JSON.stringify(
            initialObject,
            sortProperties ? Object.keys(initialObject).sort() : null,
            )
            !==
            JSON.stringify(
                currentObject,
                sortProperties ? Object.keys(currentObject).sort() : null,
            );
    }

    const brandColorTheme = computed(() => {
        let color = visualSettings.value?.themeDesignSettings?.brandColor;

        const portalLinks = [LINKS.REGISTER, LINKS.LOGIN, LINKS.DASHBOARD];
        const isPortalLink = portalLinks.some((link) => window.location.pathname.includes(link));
        if(isPortalLink && typeof portalThemeSettings.value !== 'undefined') {
            color = portalThemeSettings.value?.brandColour + '-theme';
        }
        return Object.values(LEGACY_BRAND_THEMES).includes(color) || Object.values(BRAND_THEMES).includes(color) ? color : LEGACY_BRAND_THEMES[COLOUR_LEGACY_TO_NEW_MAPPING.PURPLE];
    });

    const brandColor = computed(() => {
        return COLOUR_NEW_TO_LEGACY_MAPPING[brandColorTheme.value] || COLOUR_NEW_TO_LEGACY_MAPPING.PURPLE;
    });

    //Portal theme settings doesn't have Image
    const coverImgSrc = computed(() => {
        return visualSettings.value.bannerImage?.fileUrl.replace('/download/', '/');
    });

    const logoImgSrc = computed(() => {
        return visualSettings.value.logoImage?.fileUrl.replace('/download/', '/');
    });

    const portalLogoImgSrc = computed(() => {
        if (portalThemeSettings.value.logoImage === 'null') {
            return visualSettings.value.logoImage?.fileUrl.replace('/download/', '/');
        }
        if (portalThemeSettings.value.logoImage === '{}') {
            return visualSettings.value.logoImage?.fileUrl.replace('/download/', '/');
        }
        if (typeof portalThemeSettings.value.logoImage === 'string') {
            return visualSettings.value.logoImage?.fileUrl.replace('/download/', '/');
        }
        return portalThemeSettings.value.logoImage?.fileUrl.replace('/download/', '/');
    });

    return {
        brandColor,
        brandColorTheme,
        paymentSettings,
        visualSettings,
        goSettings,
        inventorySetting,
        settingsProgress,
        generalSetting,
        portalSettings,
        portalThemeSettings,
        coverImgSrc,
        logoImgSrc,
        portalLogoImgSrc,
        deepCopyOf,
        objectHasChanged,
    };
}
