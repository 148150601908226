import ItemLevelDiscount from './ItemLevelDiscount';
import ItemLevelTax from './ItemLevelTax';
import {Fields, Model} from '@vuex-orm/core';

export default class OrderItem extends Model {

    public static modelName = 'OrderItem';
    public static entity = 'order-items';

    public id!: number;
    public orderId!: number;
    public sku?: string;
    public description?: string;
    public serialNumber?: string;
    public quantity!: number;
    public price!: number;
    public total!: number;
    public taxable!: number;
    public quantityRefunded!: number;
    public amountRefunded!: number;
    public unitOfMeasure?: string;
    public commodityCode?: string;
    public autoCalcTax!: number;
    public taxDetails?: string;
    public amountTax!: number;
    public discountDetails?: string;
    public amountDiscount!: number;
    public amountDeposit!: number;
    public qbOnlineId!: number;
    public itemLevelDiscount?: ItemLevelDiscount;
    public itemLevelTax?: ItemLevelTax;

    public static fields(): Fields {
        return {
            id: this.number(0),
            orderId: this.number(0),
            sku: this.string(''),
            description: this.string(''),
            serialNumber: this.string(''),
            quantity: this.number(0),
            price: this.number(0),
            total: this.number(0),
            taxable: this.number(0),
            quantityRefunded: this.number(0),
            amountRefunded: this.number(0),
            unitOfMeasure: this.string(''),
            commodityCode: this.string(''),
            autoCalcTax: this.number(0),
            taxDetails: this.string(''),
            amountTax: this.number(0),
            discountDetails: this.string(''),
            amountDiscount: this.number(0),
            amountDeposit: this.number(0),
            qbOnlineId: this.number(0),
            itemLevelDiscount: this.attr({}),
            itemLevelTax: this.attr({}),
        };
    }
}
