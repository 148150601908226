import {Fields, Model} from '@vuex-orm/core';
import Merchant from './Merchant';
import Customer from './Customer';


export default class Feedback extends Model {
    public static modelName = 'RegistrationRequest';
    public static entity = 'registrationRequests';

    public id!: number;
    public dateCreated!: string;
    public dateUpdated!: string;
    public merchantId!: number;
    public customerId!: number;
    public stars!: number;
    public description!: string;
    public contactName!: string;
    public emailAddress!: string;
    public phone!: string;
    public type!: number;
    public source!: number;
    public merchant!: Merchant;
    public customer!: Customer;

    public static fields(): Fields {
        return {
            id: this.number(0),
            dateCreated: this.string('00-00-00 00:00:00'),
            dateUpdated: this.string('00-00-00 00:00:00'),
            merchantId: this.number(0),
            customerId: this.number(0),
            stars: this.number(0),
            description: this.string(''),
            contactName: this.string(''),
            emailAddress: this.string(''),
            phone: this.string(''),
            type: this.number(0),
            source: this.number(0),
            merchant: this.hasOne(Merchant, 'id', 'merchantId'),
            customer: this.hasOne(Customer, 'id', 'customerId'),
        };
    }
}
