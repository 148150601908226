import {Fields, Model} from '@vuex-orm/core';

export class TextBlockDetails extends Model {
    public status: number;
    public title: string;
    public content: string;

    public static fields(): Fields {
        return {
            status: this.number(0),
            title: this.string(''),
            content: this.string(''),
        };
    }
}

export default class TextBlock extends Model {
    public static modelName = 'TextBlock';
    public static entity = 'settings/text-blocks';

    public id: number;
    public dateCreated: string;
    public dateUpdated: string;
    public merchantId: number;
    public businessId: number;
    public textId: string;
    public textDetails: TextBlockDetails;

    public static fields(): Fields {
        return {
            id: this.number(0),
            dateCreated: this.string('0000-00-00 00:00:00'),
            dateUpdated: this.string('0000-00-00 00:00:00'),
            merchantId: this.number(0),
            businessId: this.number(0),
            textId: this.number(0),
            textDetails: this.attr({}),
        };
    }
}