import {Fields, Model} from '@vuex-orm/core';
import SubscriptionAddon from './SubscriptionAddon';

export default class PlanEstimate extends Model {
    public static modelName = 'PlanEstimate';
    public static entity = 'estimate';
    public subTotal: number;
    public taxAmount: number;
    public taxName: string;
    public total: number;
    public addons: SubscriptionAddon[];
    public estimatedDateNextBilling: string;

    public static fields(): Fields {
        return {
            subTotal: this.number(0.00),
            taxAmount: this.number(0.00),
            taxName: this.string(''),
            total: this.number(0.00),
            addons: this.hasMany(SubscriptionAddon, 'id'),
            estimatedDateNextBilling: this.string('0000-00-00 00:00:00'),
        };
    }
}
