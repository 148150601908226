import {Fields, Model} from '@vuex-orm/core';

export default class SubscriptionAddOnV2 extends Model {
    public static modelName = 'SubscriptionAddOnV2';
    public static entity = 'subscriptions-addons/v2';

    public id!: number;
    public businessId!: number;
    public dateCreated!: string;
    public dateUpdated!: string;
    public addOnId!: number;
    public subscriptionId!: number;
    public isActive!: boolean;
    public firstProcessedPaymentNumber!: number;
    public lastProcessedPaymentNumber!: number;
    public quantity!: number;

    public static fields(): Fields {
        return {
            id: this.number(0),
            businessId: this.number(0),
            dateCreated: this.string('0000-00-00 00:00:00'),
            dateUpdated: this.string('0000-00-00 00:00:00'),
            addOnId: this.number(0),
            subscriptionId: this.number(0),
            firstProcessedPaymentNumber: this.number(0),
            lastProcessedPaymentNumber: this.number(0),
            isActive: this.boolean(false),
            quantity: this.number(0.00),
        };
    }
}