import {Fields, Model} from '@vuex-orm/core';

export default class Card extends Model {
    public static modelName = 'Card';
    public static entity = 'cards';

    public cardExpiry!: string;
    public cardF4L4!: string;
    public cardHolderAddress!: string;
    public cardHolderName!: string;
    public cardHolderPostalCode!: string;
    public cardImage!: string;
    public cardL4!: string;
    public cardNumber!: string;
    public cardToken!: string;
    public cardType!: string;
    public customerId!: number;
    public dateCreated!: string;
    public dateLastUsed!: string;
    public id!: number;
    public merchantId!: number;

    public static fields(): Fields {
        return {
            cardExpiry: this.string(''),
            cardF4L4: this.string(''),
            cardHolderAddress: this.string(''),
            cardHolderName: this.string(''),
            cardHolderPostalCode: this.string(''),
            cardImage: this.string(''),
            cardL4: this.string(''),
            cardNumber: this.string(''),
            cardToken: this.string(''),
            cardType: this.string(''),
            customerId: this.number(0),
            dateCreated: this.string(''),
            dateLastUsed: this.string(''),
            id: this.number(0),
            merchantId: this.number(0),
        };
    }
}
