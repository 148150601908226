import {TIP_FORMAT, TIP_MODE} from '../scripts/constants';
import {Fields, Model} from '@vuex-orm/core';

export default class StoreTips extends Model {

    public static modelName = 'StoreTips';
    public static entity = 'store-tips';

    public id: number;
    public dateCreated: string;
    public dateUpdated: string;
    public merchantId: number;
    public tipMode: number;
    public enableMaxTip: number;
    public maximumTipType: number;
    public maximumTipAmount: number;
    public allowCustomTips: number;
    public tipRecommendType: number;
    public tipRecommendAmount1: number;
    public tipRecommendAmount2: number;
    public tipRecommendAmount3: number;
    public tipRecommendSelected: number;

    public static fields(): Fields {
        return {
            id: this.number(0),
            dateCreated: this.string('0000-00-00 00:00:00'),
            dateUpdated: this.string('0000-00-00 00:00:00'),
            merchantId: this.number(0),
            tipMode: this.number(TIP_MODE.NO_TIP),
            enableMaxTip: this.number(0),
            maximumTipType: this.number(0),
            maximumTipAmount: this.number(TIP_FORMAT.AMOUNT),
            allowCustomTips: this.number(0),
            tipRecommendType: this.number(TIP_FORMAT.AMOUNT),
            tipRecommendAmount1: this.number(0),
            tipRecommendAmount2: this.number(0),
            tipRecommendAmount3: this.number(0),
            tipRecommendSelected: this.number(0),
        };
    }
}
