import {Fields, Model} from '@vuex-orm/core';
import ThemeDesignSettings from './ThemeDesignSettings';

export default class VisualSettings extends Model {

    public static modelName = 'VisualSettings';
    public static entity = 'settings/visual-settings';

    public id: number;
    public dateCreated: string;
    public dateUpdated: string;
    public merchantId: number;
    public businessId: number;
    public themeDesignSettings: ThemeDesignSettings;
    public logoImageSrc: string;
    public gridLayout: boolean;
    public darkMode: boolean;

    public static fields(): Fields {
        return {
            id: this.number(0),
            dateCreated: this.string('0000-00-00 00:00:00'),
            dateUpdated: this.string('0000-00-00 00:00:00'),
            merchantId: this.number(0),
            businessId: this.number(0),
            themeDesignSettings: this.attr({}),
            logoImageSrc: this.string(''),
            gridLayout: this.boolean(false),
            darkMode: this.boolean(false),
        };
    }
}