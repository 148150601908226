import {Model, Fields} from '@vuex-orm/core';

export default class SettingsProgress extends Model {

    public static modelName = 'SettingsProgress';
    public static entity = 'settings-progress';

    public id!: number;
    public dateCreated!: string;
    public dateUpdated!: string;
    public merchantId!: number;
    public businessId!: number;
    public businessDetails!: number;
    public merchantPolicies!: number;
    public checkoutDetails!: number;
    public storeDesigner!: number;

    public static field(): Fields {
        return {
            id: this.number(0),
            dateCreated: this.string('0000-00-00 00:00:00'),
            dateUpdated: this.string('0000-00-00 00:00:00'),
            merchantId: this.number(0),
            businessId: this.number(0),
            businessDetails: this.number(0),
            merchantPolicies: this.number(0),
            checkoutDetails: this.number(0),
            storeDesigner: this.number(0),
        };
    }
}