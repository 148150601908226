import {Fields, Model} from '@vuex-orm/core';

export default class CustomerPortalSetting extends Model {
    public static modelName = 'CustomerPortalSetting';
    public static entity = 'customer-portal-settings';

    public id: number;
    public merchantId: number;
    public enabled: number;
    public portalName: string;
    public showPolicy: number;
    public allowViewOrders: number;
    public allowModifyInfo: number;
    public allowModifyCards: number;
    public allowSplitPayments: number;
    public allowModifyBankAccounts: number;
    public allowPayByBankAccount: number;
    public requireCardVerify: number;
    public passwordComplex: number;
    public passwordExpiryMonths: number;
    public passwordPreviousCount: number;
    public loginTimeoutMinutes: number;
    public allowViewRecurring: number;
    public paymentTermsAgree: number;
    public emailExpiredCard: number;
    public allowRegistration: number;
    public requireCard: number;
    public requireBilling: number;
    public requireShipping: number;
    public requireCreditCard: number;
    public requireBankAccount: number;

    public static fields (): Fields {
        return {
            id: this.number(0),
            merchantId: this.number(0),
            enabled: this.number(0),
            portalName: this.number(0),
            showPolicy: this.number(0),
            allowViewOrders: this.number(0),
            allowModifyInfo: this.number(0),
            allowModifyCards: this.number(0),
            allowSplitPayments: this.number(0),
            allowModifyBankAccounts: this.number(0),
            allowPayByBankAccount: this.number(0),
            requireCardVerify: this.number(0),
            passwordComplex: this.number(0),
            passwordExpiryMonths: this.number(0),
            passwordPreviousCount: this.number(0),
            loginTimeoutMinutes: this.number(0),
            allowViewRecurring: this.number(0),
            paymentTermsAgree: this.number(0),
            emailExpiredCard: this.number(0),
            allowRegistration: this.number(0),
            requireCard: this.number(0),
            requireBilling: this.number(0),
            requireShipping: this.number(0),
            requireCreditCard: this.number(0),
            requireBankAccount: this.number(0),
        };
    }
}
