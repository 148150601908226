import {
    GoSettings,
    PaymentSettings,
    VisualSettings,
    Settings,
    StoreHours,
    Feedback,
    TextBlock,
    Actors,
    Merchant,
    ShippingMethod,
    SocialLogin,
    GeneralSetting,
    PortalSettings,
    PortalThemeSettings,
} from '../types';
import {Module, ActionContext} from 'vuex';
import Address from '../models/Address';
import InventorySetting from '../models/InventorySetting';
import SettingsProgress from '../models/SettingsProgress';
import Customer from '../models/Customer';
import {InvoiceSettings, OrderReceiptSettings, OrderSetting} from '../types';
import RegistrationRequest from '../types/RegistrationRequest';

export const settings: Module<Settings|null, Settings|null> = {
    namespaced: true,
    state: null,
    mutations: {
        load(state: Settings, settings: Settings): void {
            Object.assign(state, settings);
        },
        setPaymentSettings(state: Settings, paymentSettings: PaymentSettings): void {
            Object.assign(state.paymentSettings, paymentSettings);
        },
        setGoSettings(state: Settings, goSettings: GoSettings): void {
            Object.assign(state.goSettings, goSettings);
        },
        setVisualSettings(state: Settings, visualSettings: VisualSettings): void {
            Object.assign(state.visualSettings, visualSettings);
        },
        setInventorySetting(state: Settings, inventorySetting: InventorySetting): void {
            Object.assign(state.inventorySetting, inventorySetting);
        },
        setSettingsProgress(state: Settings, settingsProgress: SettingsProgress): void {
            Object.assign(state.settingsProgress, settingsProgress);
        },
        setGeneralSetting(state: Settings, generalSetting: GeneralSetting): void {
            Object.assign(state.generalSetting, generalSetting);
        },
        setPortalSettings(state: Settings, portalSettings: PortalSettings): void {
            Object.assign(state.portalSettings, portalSettings);
        },
        setPortalThemeSettings(state: Settings, portalThemeSettings: PortalThemeSettings): void {
            Object.assign(state.portalThemeSettings, portalThemeSettings);
        },
    },
    actions: {
        init({ commit }: ActionContext<Settings|null, Settings|null>, settings: Settings): void {
            commit('load', settings);
        },
        setPaymentSettings({ commit }: ActionContext<Settings|null, Settings|null>, paymentSettings: PaymentSettings): void {
            commit('setPaymentSettings', paymentSettings);
        },
        setGoSettings({ commit }: ActionContext<Settings|null, Settings|null>, goSettings: GoSettings): void {
            commit('setGoSettings', goSettings);
        },
        setVisualSettings({ commit }: ActionContext<Settings|null, Settings|null>, visualSettings: VisualSettings): void {
            commit('setVisualSettings', visualSettings);
        },
        setInventorySetting({ commit }: ActionContext<Settings|null, Settings|null>, inventorySetting: InventorySetting): void {
            commit('setInventorySetting', inventorySetting);
        },
        setSettingsProgress({ commit }: ActionContext<Settings|null, Settings|null>, settingsProgress: SettingsProgress): void {
            commit('setSettingsProgress', settingsProgress);
        },
        setGeneralSetting({ commit }: ActionContext<Settings|null, Settings|null>, generalSetting: GeneralSetting): void {
            commit('setGeneralSetting', generalSetting);
        },
        setPortalSettings({ commit }: ActionContext<Settings|null, Settings|null>, portalSettings: PortalSettings): void {
            commit('setPortalSettings', portalSettings);
        },
        setPortalThemeSettings({ commit }: ActionContext<Settings|null, Settings|null>, portalThemeSettings: PortalThemeSettings): void {
            commit('setPortalThemeSettings', portalThemeSettings);
        },
    },
    getters: {
        go: (state: Settings|null): GoSettings|null => state?.goSettings,
        payment: (state: Settings|null): PaymentSettings|null => state?.paymentSettings,
        visual: (state: Settings|null): VisualSettings|null => state?.visualSettings,
        inventory: (state: Settings|null): InventorySetting|null => state?.inventorySetting,
        progress: (state: Settings|null): SettingsProgress|null => state?.settingsProgress,
        general: (state: Settings|null): GeneralSetting|null => state?.generalSetting,
        portal: (state: Settings|null): PortalSettings|null => state?.portalSettings,
        portalTheme: (state: Settings|null): PortalThemeSettings|null => state?.portalThemeSettings,
    },
};

export const storeHours: Module<StoreHours[]|null, StoreHours[]|null> = {
    namespaced: true,
    state: null,
    mutations: {
        load(state: StoreHours[], storeHours: StoreHours[]): void {
            // CONVERT HH:II:SS TO HH:II SO IT CAN DETECT UNSAVED CHANGES
            Object.values(storeHours).forEach((storeHour) => {
                const openTime = storeHour.openTime.split(':');
                storeHour.openTime = openTime[0] + ':' + openTime[1];
                const closeTime = storeHour.closeTime.split(':');
                storeHour.closeTime = closeTime[0] + ':' + closeTime[1];
            });
            Object.assign(state, storeHours);
        },
        setStoreHours(state: StoreHours[], storeHours: StoreHours[]): void {
            Object.assign(state, storeHours);
        },
    },
    actions: {
        init({ commit }: ActionContext<StoreHours[]|null, StoreHours[]|null>, storeHours: StoreHours[]): void {
            commit('load', storeHours);
        },
        setStoreHours({ commit }: ActionContext<StoreHours[]|null, StoreHours[]|null>, storeHours: StoreHours[]): void {
            commit('setStoreHours', storeHours);
        },
    },
    getters: {
        storeHours: (state: StoreHours[]|null): StoreHours[]|null => state,
    },
};

export const feedback: Module<Feedback[]|null, Feedback[]|null> = {
    namespaced: true,
    state: null,
    mutations: {
        load(state: Feedback[], feedback: Feedback[]): void {
            Object.assign(state, feedback);
        },
    },
    actions: {
        init({ commit }: ActionContext<Feedback[]|null, Feedback[]|null>, feedback: Feedback[]): void {
            commit('load', feedback);
        },
    },
    getters: {
        feedback: (state: Feedback[]|null): Feedback[]|null => state,
    },
};

export const registrationRequest: Module<RegistrationRequest|null, RegistrationRequest|null> = {
    namespaced: true,
    state: null,
    mutations: {
        load(state: RegistrationRequest, registrationRequest: RegistrationRequest): void {
            Object.assign(state, registrationRequest);
        },
    },
    actions: {
        init({ commit }: ActionContext<RegistrationRequest|null, RegistrationRequest|null>, registrationRequest: RegistrationRequest): void {
            commit('load', registrationRequest);
        },
    },
    getters: {
        registrationRequest: (state: RegistrationRequest|null): RegistrationRequest|null => state,
    },
};

function setOrInsert<T>(state: ObjectArray<T>, object: T, id: number) {
    const isSet = id in state.index;
    const index = isSet ? state.index[id] : state.array.length;
    if(!isSet) { state.index[id] = index; }
    state.array[index] = object;
}

interface ObjectArray<T> {
    array: T[],
    index: Record<number, number>
}

export const textBlocks: Module<ObjectArray<TextBlock>, TextBlock[]> = {
    namespaced: true,
    state: { array: [], index: {} },
    mutations: {
        load(state: ObjectArray<TextBlock>, textBlocks: Record<string, TextBlock>): void {
            Object.values(textBlocks).forEach((v: TextBlock) => setOrInsert(state, v, v.id));
        },
        setTextBlock(state: ObjectArray<TextBlock>, textBlock: TextBlock): void {
            setOrInsert(state, textBlock, textBlock.id);
        },
    },
    actions: {
        init({ commit }: ActionContext<ObjectArray<TextBlock>, TextBlock[]>, textBlocks: TextBlock[]): void {
            commit('load', textBlocks);
        },
        setTextBlock({ commit }: ActionContext<ObjectArray<TextBlock>, TextBlock[]>, textBlock: TextBlock): void {
            commit('setTextBlock', textBlock);
        },
    },
    getters: {
        textBlocks: (state: ObjectArray<TextBlock>): TextBlock[] => state.array,
    },
};

export const actors: Module<Actors|null, Actors|null> = {
    namespaced: true,
    state: null,
    mutations: {
        load(state: Actors, actors: Actors): void {
            Object.assign(state, actors);
        },
        setMerchant(state: Actors, merchant: Merchant): void {
            Object.assign(state.merchant, merchant);
        },
        setCustomer(state: Actors, customer: Customer): void {
            if(!state.customer) { state.customer = new Customer; }
            Object.assign(state.customer, customer);
        },
        setMerchantAddress(state: Actors, address: Address): void {
            if(!state.merchant.address) { state.merchant.address = new Address; }
            Object.assign(state.merchant.address, address);
        },
    },
    actions: {
        init({ commit }: ActionContext<Actors|null, Actors|null>, actors: Actors): void {
            commit('load', actors);
        },
        setMerchant({ commit }: ActionContext<Actors|null, Actors|null>, merchant: Merchant): void {
            commit('setMerchant', merchant);
        },
        setCustomer({ commit }: ActionContext<Actors|null, Actors|null>, customer: Customer): void {
            commit('setCustomer', customer);
        },
        setMerchantAddress({ commit }: ActionContext<Actors|null, Actors|null>, address: Address): void {
            commit('setMerchantAddress', address);
        },
    },
    getters: {
        merchant: (state: Actors|null): Merchant|null => state?.merchant,
        customer: (state: Actors|null): Customer|null => state?.customer,
    },
};

export const invoiceSettings: Module<InvoiceSettings|null, InvoiceSettings|null> = {
    namespaced: true,
    state: null,
    mutations: {
        load(state: InvoiceSettings, invoiceSettings: InvoiceSettings): void {
              Object.assign(state, invoiceSettings);
        },
        setOrderReceiptSetting(state: InvoiceSettings, orderReceiptSetting: OrderReceiptSettings): void {
            Object.assign(state.orderReceiptSetting, orderReceiptSetting);
        },
        setOrderSetting(state: InvoiceSettings, orderSetting: OrderSetting): void {
            Object.assign(state.orderSetting, orderSetting);
        },
    },
    actions: {
        init({ commit }: ActionContext<InvoiceSettings|null, InvoiceSettings|null>, invoiceSettings: InvoiceSettings): void {
            commit('load', invoiceSettings);
        },
        setOrderReceiptSetting({ commit }: ActionContext<InvoiceSettings|null, InvoiceSettings|null>, orderReceiptSetting: OrderReceiptSettings): void {
            commit('setOrderReceiptSetting', orderReceiptSetting);
        },
        setOrderSetting({ commit }: ActionContext<InvoiceSettings|null, InvoiceSettings|null>, orderSetting: OrderSetting): void {
            commit('setOrderReceiptSetting', orderSetting);
        },
    },
    getters: {
        orderReceiptSetting: (state: InvoiceSettings|null): OrderReceiptSettings|null => state?.orderReceiptSetting,
        orderSetting: (state: InvoiceSettings|null): OrderSetting|null => state?.orderSetting,
    },
};

export const socialLogin: Module<SocialLogin|null, SocialLogin|null> = {
    namespaced: true,
    state: null,
    mutations: {
        load(state: SocialLogin, socialLogin: SocialLogin): void {
            Object.assign(state, socialLogin);
        },
        setSocialLoginDomain(state: SocialLogin, socialLoginDomain: string): void {
            Object.assign(state.socialLoginDomain, socialLoginDomain);
        },
    },
    actions: {
        init({ commit }: ActionContext<SocialLogin|null, SocialLogin|null>, socialLogin: SocialLogin): void {
            commit('load', socialLogin);
        },
        setOrderSetting({ commit }: ActionContext<SocialLogin|null, SocialLogin|null>, socialLoginDomain: string): void {
            commit('setSocialLoginDomain', socialLoginDomain);
        },
    },
    getters: {
        socialLoginDomain: (state: SocialLogin|null): string => state?.socialLoginDomain,
    },
};

export const shippingMethods: Module<ObjectArray<ShippingMethod>, ShippingMethod[]> = {
    namespaced: true,
    state: { array: [], index: {} },
    mutations: {
        load(state: ObjectArray<ShippingMethod>, shippingMethods: Record<string, unknown>): void {
            Object.values(shippingMethods.items).forEach((v: ShippingMethod) => setOrInsert(state, v, v.id));
        },
    },
    actions: {
        init({ commit }: ActionContext<ObjectArray<ShippingMethod>, ShippingMethod[]>, shippingMethods: ShippingMethod[]): void {
            commit('load', shippingMethods);
        },
    },
    getters: {
        shippingMethods: (state: ObjectArray<ShippingMethod>): ShippingMethod[] => state.array,
    },
};
