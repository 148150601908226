import {computed} from 'vue';
import {Store, useStore} from 'vuex';
import {Actors, Merchant} from '../types';
import Customer from '../models/Customer';

export function useActors() {

    const store: Store<Actors> = useStore();

    const merchant = computed<Merchant>(() => store.getters['actors/merchant']);
    const customer = computed<Customer>(() => store.getters['actors/customer']);

    return {
        merchant,
        customer,
    };

}
