import {Address, CartShippingDetail, CartTotal, DiscountCode, ICart} from '../../types';

const isCart = (payload: unknown): payload is ICart => {
    return payload !== undefined;
};

export default class Cart implements ICart {
    public id: number;
    public dateCreated: string;
    public dateUpdated: string;
    public merchantId: number;
    public customerId: number;
    public orderId: number;
    public currencyId: number;
    public shippingAddressId: number;
    public billingAddressId: number;
    public discountCodeId: number;
    public notes: string;
    public cartTotalId: number;
    public cartShippingDetailId: number;
    public pickupTime: string;
    public cartTotal?: CartTotal;
    public shippingAddress?: Address;
    public billingAddress?: Address;
    public discountCode?: DiscountCode;
    public cartShippingDetail?: CartShippingDetail;

    public constructor(cart?: unknown) {
        if(!isCart(cart)) {
            return;
        }

        this.id = cart.id;
        this.dateCreated = cart.dateCreated;
        this.dateUpdated = cart.dateUpdated;
        this.merchantId = cart.merchantId;
        this.customerId = cart.customerId;
        this.orderId = cart.orderId;
        this.currencyId = cart.currencyId;
        this.shippingAddressId = cart.shippingAddressId;
        this.billingAddressId = cart.billingAddressId;
        this.discountCodeId = cart.discountCodeId;
        this.notes = cart.notes;
        this.cartTotalId = cart.cartTotalId;
        this.cartShippingDetailId = cart.cartShippingDetailId;
        this.pickupTime = cart.pickupTime;
        this.cartTotal = cart.cartTotal;
        this.shippingAddress = cart.shippingAddress;
        this.billingAddress = cart.billingAddress;
        this.discountCode = cart.discountCode;
        this.cartShippingDetail = cart.cartShippingDetail;
    }
}
