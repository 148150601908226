import {Fields, Model} from '@vuex-orm/core';

export default class TransactionOther extends Model {
    public static modelName = 'TransactionOther';
    public static entity = 'transaction-others';

    public id: number;
    public merchantId: number;
    public userId: number;
    public orderId: number;
    public dateCreated: string;
    public status: number;
    public tenderType: number;
    public giftCardId: number;
    public amount: number;
    public amountTip: number;
    public currency: number;
    public note?: string;
    public description?: string;

    public static fields(): Fields {
        return {
            id: this.number(0),
            merchantId: this.number(0),
            userId: this.number(0),
            orderId: this.number(0),
            dateCreated: this.string('0000-00-00 00:00:00'),
            status: this.number(0),
            tenderType: this.number(0),
            giftCardId: this.number(0),
            amount: this.number(0),
            amountTip: this.number(0),
            currency: this.number(0),
            note: this.string(''),
            description: this.string(''),
        };
    }
}
