import axios from 'axios';
import Vuex from 'vuex';
import VuexORM, {Model} from '@vuex-orm/core';
import VuexORMAxios from '@vuex-orm/plugin-axios';
import {
	settings,
	storeHours,
	feedback,
	textBlocks,
	actors,
	invoiceSettings,
	socialLogin,
	registrationRequest,
	shippingMethods,
} from './modules';

VuexORM.use(VuexORMAxios, { axios });

const database = new VuexORM.Database;
Object.values(import.meta.glob('../models/*.ts', { eager: true })).forEach((file: { default: typeof Model }) => {
	database.register(file.default);
});

export const store = new Vuex.Store({
    plugins: [VuexORM.install(database)],
    modules: {
        settings: settings,
        storeHours: storeHours,
        feedback: feedback,
		registrationRequest: registrationRequest,
        textBlocks: textBlocks,
        actors: actors,
        invoiceSettings: invoiceSettings,
		shippingMethods: shippingMethods,
		socialLogin: socialLogin,
    },

	// for capturing current product view
	state: {
		productInView: {},
	},
	getters: {
		productInView: state => {
			return state.productInView;
		},
	},
	mutations: {
		changeProductInView(state, payload) {
			state.productInView = payload;
		},
	},
});
