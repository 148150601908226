import {Fields, Model} from '@vuex-orm/core';

export default class Transaction extends Model {
    public static modelName = 'Transaction';
    public static entity = 'transactions';

    public id: number;
    public merchantId: number;
    public orderId: number;
    public dateCreated: string;
    public status: number;
    public description?: string;
    public userId: number;
    public cardId: number;
    public batchId: number;
    public type: number;
    public amount: number;
    public amountTip: number;
    public currency: number;
    public cardType: number;
    public cardNumber: string;
    public cardExpiry: number;
    public cardHolderName: string;
    public approvalCode: string;
    public avsResponse?: string;
    public cvvResponse?: string;
    public test: number;
    public pairTransactionId: number;
    public preauthTransactionId: number;
    public acquirerTransactionId: string;
    public responseMessage?: string;
    public payFacTransactionId: number;

    public static fields(): Fields {
        return {
            id: this.number(0),
            merchantId: this.number(0),
            orderId: this.number(0),
            dateCreated: this.string('0000-00-00 00:00:00'),
            status: this.number(0),
            description: this.string(''),
            userId: this.number(0),
            cardId: this.number(0),
            batchId: this.number(0),
            type: this.number(0),
            amount: this.number(0),
            amountTip: this.number(0),
            currency: this.number(0),
            cardType: this.number(0),
            cardNumber: this.string(''),
            cardExpiry: this.number(0),
            cardHolderName: this.string(''),
            approvalCode: this.string(''),
            avsResponse: this.string(''),
            cvvResponse: this.string(''),
            test: this.number(0),
            pairTransactionId: this.number(0),
            preauthTransactionId: this.number(0),
            acquirerTransactionId: this.string(''),
            responseMessage: this.string(''),
            payFacTransactionId: this.number(0),
        };
    }
}
