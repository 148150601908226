import {Fields, Model} from '@vuex-orm/core';

export default class QuoteApproval extends Model {
    public static modelName = 'QuoteApproval';
    public static entity = 'quote-approvals';

    public id!: number;
    public dateCreated!: string;
    public merchantId!: number;
    public invoiceId!: number;
    public signature?: string;
    public dateSigned?: string;

    public static fields(): Fields {
        return {
            id: this.number(0),
            dateCreated: this.string('0000-00-00 00:00:00'),
            merchantId: this.number(0),
            invoiceId: this.number(0),
            signature: this.string(''),
            dateSigned: this.string(''),
        };
    }
}
