import {defineStore} from 'pinia';
import {computed, ref} from 'vue';
import {useAxios} from '../compositions/utilities/useAxios';
import {AxiosResponse} from 'axios';
import Category from './classes/Category';
import Product from './classes/Product';
import {destructurePayload, isValidObject, logger} from './utilities';


const orderCategories = (categories: Category[], lastId = 0): Category[] => {
    return categories.filter(({parentId}) => lastId ? parentId === lastId : parentId === 0)
        .sort((first, last) => first.orderCount - last.orderCount)
        .reduce<Category[]>((result, current) => {
            return [...result, current, ...orderCategories(categories, current.id)];
        }, []);
};

const gridRows = <T, >(array: T[], columns: number): T[][] => {
    return Array.from(
        new Array(Math.ceil(array.length / columns)),
        (_, i) => array.slice(i * columns, i * columns + columns),
    );
};

export const gridHeight = (containerWidth: number, padding: number, columns: number, textHeight: number): number => {
    return (containerWidth - (padding * (columns + 1))) / columns * 5 / 4 + textHeight;
};

export const useProductStore = defineStore('product-store', () => {
    const { axiosGet } = useAxios();

    const categories = ref(new Map<number, Category>([]));
    const products = ref(new Map<number, Product>([]));

    const categoryList = computed(() => [...categories.value.values()]);
    const productList = computed(() => [...products.value.values()]);

    const scrollerList = computed(() => {
        let productList: ({ name: string, id: number, typez: string, size: number } | Product)[] = [];
        categoryList.value.forEach(v => {
            const section = { name: v.name, id: v.id, typez: 'cat', size: 56 };
            productList = [...productList, section, ...v.products];
        });
        return productList;
    });

    const scrollerGrid = (columns: number) => {
        let productList: ({ items: {name: string, typez: string}[], id: number, size?: number } | { items: Product[], id: number, size?: number })[] = [];
        categoryList.value.forEach(v => {
            const section = { items: [{name: v.name, typez: 'cat'}], id: v.id, size: 56 };
            const productArray = gridRows(v.products, columns).map((gr, i) => ({ id: v.id * (i+ 1 + v.id + 1) + i, items: gr }));
            productList = [...productList, section, ...productArray];
        });
        return productList;
    };

    const registerProducts = (payload: Record<string, unknown>[]) => {
        if(!payload) {
            return;
        }
        const orderedCategories = orderCategories(payload.map(v => new Category(v)));
        orderedCategories.forEach(item => {
            categories.value.set(item.id, item);
            item.products.forEach(product => products.value.set(product.id, product));
        });
    };

    async function initProducts() {
        await axiosGet<AxiosResponse & { items: unknown }>('/categories')
            .then((response) => {
                if(!isValidObject(response.items)) {
                    throw new Error('Invalid response.');
                }
                const payload = destructurePayload(response.items);
                registerProducts(payload);
            })
            .catch(reason => {
                logger.warn('Failed to fetch products.', reason);
            });
        await axiosGet<AxiosResponse & { items: unknown }>('/products')
            .then((response) => {
                if(!isValidObject(response.items)) {
                    throw new Error('Invalid response.');
                }
                const payload = destructurePayload(response.items);
                const otherProducts = payload
                    .filter(v => v?.categoryId === 0);
                if(categories.value.size > 0 && otherProducts.length > 0) {
                    const otherCategory = new Category({
                        id: -1,
                        name: 'Other',
                        products: otherProducts,
                    });
                    otherCategory.products.forEach(product => products.value.set(product.id, product));
                    categories.value.set(-1, otherCategory);
                } else if (!categories.value.size && payload.length) {
                    const allCategory = new Category({
                        id: -2,
                        name: 'All',
                        products: payload,
                    });
                    allCategory.products.forEach(product => products.value.set(product.id, product));
                    categories.value.set(-2, allCategory);
                }
            })
            .catch(reason => {
                logger.warn('Failed to fetch products.', reason);
            });
    }

    return {
        initProducts,
        categories,
        products,
        categoryList,
        productList,
        scrollerList,
        scrollerGrid,
    };
});
