import {Fields, Model} from '@vuex-orm/core';

export default class CustomerPortalTheme extends Model {
    public static modelName = 'CustomerPortalTheme';
    public static entity = 'customer-portal-themes';

    public id: number;
    public merchantId: number;
    public logoImageId: number;
    public presetThemeId: number;
    public presetColors: string;
    public customCSS: string;
    public customCSSMobile: string;
    public logoImageString: string;
    public randomBannerImage: string;
    public themeColor: string;

    public static fields (): Fields {
        return {
            id: this.number(0),
            merchantId: this.number(0),
            logoImageId: this.number(0),
            presetThemeId: this.number(0),
            presetColors: this.string(''),
            customCSS: this.string(''),
            customCSSMobile: this.string(''),
            logoImageString: this.string(''),
            randomBannerImage: this.string(''),
            themeColor: this.string(''),
        };
    }

}
