import {Fields, Model} from '@vuex-orm/core';
import ShippingMethodRange from './ShippingMethodRange';
import {SHIPPING_TYPES} from '../../../admin/src/common/constants/SHIPPING_TYPES';

export default class ShippingMethod extends Model {

    public static modelName = 'ShippingMethods';
    public static entity = 'shipping-methods';

    public id: number;
    public merchantId: number;
    public dateCreated: string;
    public dateUpdated: string;
    public shippingRegionCountry: string;
    public countryId: number;
    public provinceId: number;
    public postalCode: string;
    public name: string;
    public type: number;
    public freeShipping: number;
    public freeShippingAmount: number;
    public shipperField1: string;
    public shipperField2: string;
    public shipperField3: string;
    public shipperField4: string;
    public shipperField5: string;
    public shipperField6: string;
    public shipperField7: string;
    public shipperField8: string;
    public shipperField9: string;
    public shipperField10: string;
    public ranges: ShippingMethodRange[];

    public static fields(): Fields {
        return {
            id: this.number(0),
            merchantId: this.number(0),
            dateCreated: this.string('0000-00-00 00:00:00'),
            dateUpdated: this.string('0000-00-00 00:00:00'),
            shippingRegionCountry: this.string(''),
            countryId: this.number(0),
            provinceId: this.number(0),
            postalCode: this.string(''),
            name: this.string(''),
            type: this.number(SHIPPING_TYPES.BY_WEIGHT),
            freeShipping: this.number(0),
            freeShippingAmount: this.number(0),
            shipperField1: this.string(''),
            shipperField2: this.string(''),
            shipperField3: this.string(''),
            shipperField4: this.string(''),
            shipperField5: this.string(''),
            shipperField6: this.string(''),
            shipperField7: this.string(''),
            shipperField8: this.string(''),
            shipperField9: this.string(''),
            shipperField10: this.string(''),
            ranges: this.hasMany(ShippingMethodRange, 'shippingMethodId'),
        };
    }
}
