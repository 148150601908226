import {Fields, Model} from '@vuex-orm/core';

export default class StoreHours extends Model {

    public static modelName = 'StoreHours';
    public static entity = 'store-hours';

    public id: number;
    public dateCreated: string;
    public dateUpdated: string;
    public merchantId: number;
    public businessId: number;
    public day: number;
    public openTime: string;
    public closeTime: string;
    public isOpen: boolean;

    public static fields(): Fields {
        return {
            id: this.number(0),
            dateCreated: this.string('0000-00-00 00:00:00'),
            dateUpdated: this.string('0000-00-00 00:00:00'),
            merchantId: this.number(0),
            businessId: this.number(0),
            day: this.number(0),
            openTime: this.string('00:00:00'),
            closeTime: this.string('00:00:00'),
            isOpen: this.boolean(false),
        };
    }

}