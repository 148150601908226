import {Fields, Model} from '@vuex-orm/core';
import SubscriptionItem from './SubscriptionItem';
import RecurringPlan from './RecurringPlan';
import Order from './Order';

export default class Subscription extends Model {
    public static modelName = 'Subscription';
    public static entity = 'subscriptions';

    public id: number;
    public merchantId: number;
    public recurringId: number;
    public customerId: number;
    public cardId: number;
    public dateCreated: string;
    public dateStarted: string;
    public dateRemoved: string;
    public status: number;
    public eft: number;
    public token: string;
    public nickname: string;
    public amountInitial: number;
    public amountRecurring: number;
    public cyclesBilled: number;
    public cyclesTotal: number;
    public dueOrderId: number;
    public recurringPlan: RecurringPlan;
    public subscriptionItems: SubscriptionItem[];
    public invoices: Order[];
    public dateNextBilling: string;

    public static fields (): Fields {
        return {
            id: this.number(0),
            merchantId: this.number(0),
            recurringId: this.number(0),
            customerId: this.number(0),
            cardId: this.number(0),
            dateCreated: this.string('00:00:00 00:00:00'),
            dateStarted: this.string('00:00:00 00:00:00'),
            dateRemoved: this.string('00:00:00 00:00:00'),
            status: this.number(0),
            eft: this.number(0),
            token: this.string(''),
            nickname: this.string(''),
            amountInitial: this.number(0.00),
            amountRecurring: this.number(0.00),
            cyclesBilled: this.number(0),
            cyclesTotal: this.number(0),
            dueOrderId: this.number(0),
            recurringPlan: this.hasOne(RecurringPlan, 'id', 'recurringId'),
            subscriptionItems: this.hasMany(SubscriptionItem, 'subscriptionId', 'id'),
            invoices: this.hasMany(Order, 'subscriptionId', 'id'),
            dateNextBilling: this.string('00:00:00 00:00:00'),
        };
    }

}
