import type {
    DirectiveBinding,
    ObjectDirective,
} from 'vue';

export interface ResizeDirectiveBinding extends Omit<DirectiveBinding, 'value'> {
    value?: ResizeObserverCallback | { handler: ResizeObserverCallback, options?: ResizeObserverOptions }
}

interface HiddenObserver {
    _observe: {
        resize: ResizeObserver
    },
}

function mounted (el: HTMLElement & HiddenObserver, binding: ResizeDirectiveBinding): void {
    const value = binding.value;
    const { handler, options } = typeof value === 'object'
        ? value
        : { handler: value, options: {} };

    const observer = new ResizeObserver((
        entries: ResizeObserverEntry[],
        observer: ResizeObserver,
    ) => {
        if (!el._observe) {
            return;
        }

        if (handler) {
            handler(entries, observer);
        }
    });

    el._observe = { resize: observer };

    observer.observe(el, options);
}

function unmounted (el: HTMLElement & HiddenObserver): void {
    if (!el._observe) {
        return;
    }

    el._observe.resize.disconnect();
    delete el._observe;
}

const ResizeDirective: ObjectDirective<HTMLElement> = {
    mounted,
    unmounted,
};

export default ResizeDirective;