import {Fields, Model} from '@vuex-orm/core';

export default class PickupTime extends Model {

    public static modelName = 'PickupTime';
    public static entity = 'pickup';
    public id: number;
    public time: string;

    public static fields(): Fields {
        return {
            id: this.number(0),
            time: this.string(''),
        };
    }

}