import {Fields, Model} from '@vuex-orm/core';
import Link from './Link';

export default class GoSettings extends Model {

    public static modelName = 'GoSettings';
    public static entity = 'settings/go-settings';

    public id: number;
    public merchantId: number;
    public businessId: number;
    public onlineStoreActive: boolean;
    public enableStoreFeedback: boolean;
    public allowStoreEmbedding: boolean;
    public tablesReadyIntegrated: boolean;
    public demoMode: boolean;
    public apiToken: string;
    public pickupEnabled: boolean;
    public shippingEnabled: boolean;
    public allowDifferentPickupInfo: boolean;
    public preOrderDays: number;
    public bufferTime: number;
    public timeInterval: number;
    public orderLimit: number;
    public limitCheckoutToStoreHours: boolean;
    public enableLinks: boolean;
    public links: Link[];
    public allowDifferentBilling: boolean;
    public allowViewOrders: boolean;
    public allowModifyInfo: boolean;
    public allowModifyCards: boolean;
    public allowSplitPayments: boolean;
    public allowModifyBankAccounts: boolean;
    public allowPayByBankAccount: boolean;
    public allowViewRecurring: boolean;
    public allowRegistration: boolean;
    public requireCard: boolean;
    public requireCardVerify: boolean;
    public requireBilling: boolean;
    public requireShipping: boolean;
    public requireCreditCard: boolean;
    public requireBankAccount: boolean;
    public dateCreated: string;
    public dateUpdated: string;
    public portalActive: boolean;
    public planTermsAgree: boolean;


    public static fields(): Fields {
        return {

            id: this.number(0),
            merchantId: this.number(0),
            businessId: this.number(0),
            onlineStoreActive: this.boolean(false),
            enableStoreFeedback: this.boolean(false),
            allowStoreEmbedding: this.boolean(false),
            tablesReadyIntegrated: this.boolean(false),
            demoMode: this.boolean(false),
            apiToken: this.string(''),
            pickupEnabled: this.boolean(false),
            shippingEnabled: this.boolean(false),
            allowDifferentPickupInfo: this.boolean(false),
            preOrderDays: this.number(0),
            bufferTime: this.number(0),
            timeInterval: this.number(0),
            orderLimit: this.number(0),
            limitCheckoutToStoreHours: this.boolean(false),
            enableLinks: this.boolean(false),
            links: this.attr({}),
            allowDifferentBilling: this.boolean(false),
            allowViewOrders: this.boolean(false),
            allowModifyInfo: this.boolean(false),
            allowModifyCards: this.boolean(false),
            allowSplitPayments: this.boolean(false),
            allowModifyBankAccounts: this.boolean(false),
            allowPayByBankAccount: this.boolean(false),
            allowViewRecurring: this.boolean(false),
            allowRegistration: this.boolean(false),
            requireCard: this.boolean(false),
            requireCardVerify: this.boolean(false),
            requireBilling: this.boolean(false),
            requireShipping: this.boolean(false),
            requireCreditCard: this.boolean(false),
            requireBankAccount: this.boolean(false),
            dateCreated: this.string('00-00-00 00:00:00'),
            dateUpdated: this.string('00-00-00 00:00:00'),
            portalActive: this.boolean(false),
            planTermsAgree: this.boolean(false),
        };
    }

}
