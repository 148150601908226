import Product from '../classes/Product';
import {PROMO_BUY_TYPE, PROMO_GET_TYPE, STOCK_TYPE, VARIANT_STATUS} from '../../scripts/constants';
import InventorySetting from '../../models/InventorySetting';
import {BuyGetPromotion, ProductVariant} from '../../types';
import {useUtilCurrency} from '../../compositions';

export const isArray = (payload: unknown): payload is unknown[] => {
    return Array.isArray(payload);
};

export const isValidObject = (payload: unknown): payload is Record<string, unknown> => {
    if(typeof payload !== 'object' || isArray(payload)) {
        return false;
    }
    return Object.keys(payload).every(k => typeof k === 'string');
};

export const isObjectArray = (payload: Record<string, unknown>): payload is Record<string, Record<string, unknown>> => {
    return Object.entries(payload).every(([k, v]) => {
        return !isNaN(+k) && isValidObject(v);
    });
};

export const hasProperty = <T, S extends string>(obj: T, prop: S): obj is T & Record<S, unknown> =>
    Object.prototype.hasOwnProperty.call(obj, prop);

export const destructurePayload = (payload: Record<string, unknown>) => {
    if(isObjectArray(payload)) {
        const items = [...Object.values(payload)];
        items.forEach(item => {
            Object.entries(item).forEach(([k, v]) => {
                if(!isValidObject(v)) {
                    return;
                }
                const result = destructurePayload(v);
                if(result) {
                    item[k] = result;
                }
            });
        });
        return items;
    }
    return null;
};

const loggerBase = (type: 'error' | 'warn' | 'log' | 'info' | 'debug') => {
    return (...data: any[]) => {
        if (import.meta.env.DEV || import.meta.hot) {
            (console[type])(...data);
        }
    };
};

export const logger = {
    error: loggerBase('error'),
    warn: loggerBase('warn'),
    log: loggerBase('log'),
    info: loggerBase('info'),
    debug: loggerBase('debug'),
};

const { displayWithCurrency } = useUtilCurrency();

export function isVariantOutOfStock(variant: ProductVariant): boolean {
    return variant.stock <= 0;
}

function hasVariants(product: Product): boolean {
    return product.variantStatus === 1 && product.productVariants?.length > 0;
}

export function isProductOutOfStock(product: Product): boolean {
    if (product.stockType === STOCK_TYPE.BY_VARIANT) {
        if (product.variantStatus === VARIANT_STATUS.IS_PRODUCT) {
            return product.stock <= 0;
        }
        return hasVariants(product) && product.productVariants.every((pv) => isVariantOutOfStock(pv));
    }

    if (product.stockType === STOCK_TYPE.BY_PRODUCT) {
        return product.stock <= 0;
    }
    return false;
}

export function isOutOfStock(offering: Product, inventorySetting: InventorySetting): boolean {
    if(!inventorySetting.onlineStoreUpdate || inventorySetting.onlineStoreBackorder) {
        return false;
    }

    if (offering instanceof Product) {
        return offering.trackInventory === 0 ? false : isProductOutOfStock(offering);
    }

    return false;
}

export function buildBogoCatchPhrase(buyGetPromotion: BuyGetPromotion): string {
    const buyPhrase = buildBuyPhrase(buyGetPromotion.buyQuantity, buyGetPromotion.buyType);
    const getPhrase = buildGetPhrase(buyGetPromotion.getQuantity, buyGetPromotion.getType);
    return `${buyPhrase} ${getPhrase}`;
}

function buildBuyPhrase(quantity: number, buyType: number): string {
    if(buyType === PROMO_BUY_TYPE.MIN_PURCHASE) {
        return `Buy ${displayWithCurrency(quantity)}`;
    }
    return `Buy ${quantity}`;
}

function buildGetPhrase(quantity: number, getType: number): string {
    if(getType === PROMO_GET_TYPE.AMOUNT_OFF) {
        return `Get ${displayWithCurrency(quantity)} Off`;
    }
    if(getType === PROMO_GET_TYPE.PERCENTAGE_OFF) {
        return `Get ${quantity}% Off`;
    }
    return `Get ${quantity} Free`;
}
