import {Fields, Model} from '@vuex-orm/core';
import ShippingMethod from './ShippingMethod';

export default class ShippingMethodOption extends Model {

    public static modelName = 'ShippingMethodOption';
    public static entity = 'shipping-method-options';

    public calculatedAmount: number;
    public shippingMethod: ShippingMethod;

    public static fields(): Fields {
        return {
            calculatedAmount: this.number(0),
            shippingMethod: this.attr({}),
        };
    }
}
